.axs-thumbnail-text {
	display: block;
	padding-bottom: calc(var(--space-xxxl) - var(--space-xxl));
	padding-top: var(--space-xxl);
}

.axs-thumbnail-text--background-grey {
	background-color: var(--color-grey-05);
}

.axs-thumbnail-text--background-black {
	background-color: var(--color-black);
	color: var(--color-white);
}

.axs-thumbnail-text__headline,
.axs-thumbnail-text__item-headline,
.axs-thumbnail-text__item-copy {
	color: inherit;
}

.axs-thumbnail-text__headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
	text-align: center;
}

.axs-thumbnail-text__item {
	margin-bottom: var(--space-xxl);
}

.axs-thumbnail-text__item-content {
	transition: transform 750ms var(--transition-ease-out), opacity 750ms var(--transition-ease-out) 42ms;
}

.axs-thumbnail-text__item-image {
	width: 100%;
}

.axs-thumbnail-text__item-picture {
	display: block;
	margin-bottom: var(--space-m);
}

.axs-thumbnail-text__item-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
}

.axs-thumbnail-text__item-copy {
	margin-bottom: var(--space-l);
}

.axs-thumbnail-text__item-headline:last-child,
.axs-thumbnail-text__item-copy:last-child,
.axs-thumbnail-text__item-content > .axs-thumbnail-text__item-picture:last-child,
.axs-thumbnail-text__item-picture-link:last-child .axs-thumbnail-text__item-picture {
	margin-bottom: 0;
}

.axs-thumbnail-text__item-link {
	display: block;
}

.axs-thumbnail-text__item-link + .axs-thumbnail-text__item-link {
	margin-top: var(--space-xxs);
}

.axs-thumbnail-text__item[data-scroll-reveal="hidden"] .axs-thumbnail-text__item-content {
	opacity: 0;
	transform: translateY(160px);
}

.axs-thumbnail-text[data-ismultiline="false"] .axs-thumbnail-text__item:nth-child(2) .axs-thumbnail-text__item-content {
	transition-delay: 42ms;
}

.axs-thumbnail-text[data-ismultiline="false"] .axs-thumbnail-text__item:nth-child(3) .axs-thumbnail-text__item-content {
	transition-delay: 84ms;
}

.axs-thumbnail-text[data-ismultiline="false"] .axs-thumbnail-text__item:nth-child(4) .axs-thumbnail-text__item-content {
	transition-delay: 126ms;
}
