.axs-iframe {
	display: block;
}

.axs-iframe__iframe-content--hidden {
	visibility: hidden;
}

.axs-iframe__inner-wrapper {
	position: relative;
}

.axs-iframe__iframe-content {
	border: none;
	display: block;
	height: 100%;
	width: 100%;
}

.axs-iframe--rules-for-padding-bottom .axs-iframe__inner-wrapper {
	height: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.axs-iframe--rules-for-padding-bottom .axs-iframe__iframe-content {
	left: 0;
	position: absolute;
	top: 0;
}

.axs-iframe--pageformat-FORMAT1x1 .axs-iframe__inner-wrapper {
	padding-bottom: 100%;
}

.axs-iframe--pageformat-FORMAT1x2 .axs-iframe__inner-wrapper {
	padding-bottom: 200%;
}

.axs-iframe--pageformat-FORMAT1x4 .axs-iframe__inner-wrapper {
	padding-bottom: 400%;
}

.axs-iframe--pageformat-FORMAT1x6 .axs-iframe__inner-wrapper {
	padding-bottom: 600%;
}

.axs-iframe--pageformat-FORMAT16x9 .axs-iframe__inner-wrapper {
	padding-bottom: 56.25%;
}

.axs-iframe--pageformat-FORMAT4x3 .axs-iframe__inner-wrapper {
	padding-bottom: 75%;
}

/*
	Fix for Safari-Bug: Fixed position elements with value for z-index overlay the fullscreen iframe content
*/
:-webkit-full-screen-ancestor :not(iframe) {
	z-index: -100 !important;
}
