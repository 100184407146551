/* AXS-4258 - smaller top padding for the faq module only */
.axs-module-background + .axs-module.axs-md-faq {
	padding-top: 36px;
}

.axs-faq-headline {
	margin-bottom: 0;
	padding-bottom: 36px;
}

.axs-faq-panels {
	display: block;
	position: relative;
}

.axs-faq-input {
	left: -10000px;
	position: absolute;
}

.axs-faq-tab {
	background-color: var(--color-grey-05);
	cursor: pointer;
	display: block;
	margin-bottom: 3px;
	padding: 18px 0;
	padding-left: 15px;
	position: relative;
}

.axs-faq-tab svg {
	position: absolute;
	right: 6px;
	transform: rotate(90deg);
}

.axs-faq-tab:last-of-type {
	margin-right: 0;
}

.axs-faq-panel {
	display: none;
	margin-bottom: 30px;
	width: 100%;
}

.axs-faq-input:checked + .axs-faq-tab {
	margin-bottom: 30px;
}

.axs-faq-input:checked + .axs-faq-tab svg {
	transform: rotate(90deg) scaleX(-1);
}

.axs-faq-input:checked + .axs-faq-tab + .axs-faq-panel {
	display: block;
}

@media all and (min-width: 768px) {
	.axs-faq-panel {
		margin-bottom: 0;
	}

	.axs-faq-panels {
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
	}

	.axs-faq-tab {
		background: none;
		display: inline-block;
		margin-bottom: 30px;
		margin-right: 30px;
		opacity: .5;
		order: -1;
		padding: 0 0 4px;
		transition: opacity .3s ease-in-out;
	}

	.axs-faq-tab:hover {
		opacity: 1;
	}

	.axs-faq-tab svg {
		display: none;
	}

	.axs-faq-input:checked + .axs-faq-tab,
	.axs-faq-input.show + .axs-faq-tab {
		opacity: 1;
	}

	.axs-faq-input.show + .axs-faq-tab + .axs-faq-panel {
		display: block;
	}

	.axs-faq-tab-indicator {
		border-bottom: 2px solid var(--color-black);
		position: absolute;
		top: 22px;
		transition: left .3s var(--transition-ease-in-out), width .3s var(--transition-ease-in-out), opacity .3s var(--transition-ease-in-out);
		width: 0;
	}

	.axs-faq-tab-indicator.no-animation {
		transition: none;
	}
}

@media all and (min-width: 1024px) {
	.axs-module-background + .axs-module.axs-md-faq {
		padding-top: 42px;
	}

	.axs-faq-headline {
		padding-bottom: 42px;
	}
}

@media all and (min-width: 1440px) {
	.axs-module-background + .axs-module.axs-md-faq {
		padding-top: 54px;
	}

	.axs-faq-headline {
		padding-bottom: 54px;
	}

	.axs-faq-tab {
		margin-bottom: 36px;
		margin-right: 36px;
	}

	.axs-faq-input:checked + .axs-faq-tab {
		margin-bottom: 36px;
	}
}

@media all and (min-width: 1920px) {
	.axs-module-background + .axs-module.axs-md-faq {
		padding-top: 66px;
	}

	.axs-faq-headline {
		padding-bottom: 66px;
	}

	.axs-faq-tab {
		margin-bottom: 42px;
		margin-right: 42px;
	}

	.axs-faq-input:checked + .axs-faq-tab {
		margin-bottom: 42px;
	}
}
