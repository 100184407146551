.axs-md-accordion {
	border-bottom: 1px solid var(--color-grey-30);
	width: 100%;
}

.axs-md-accordion-item {
	border-top: 1px solid var(--color-grey-30);
	display: block;
	height: auto;
	padding: 0;
	position: relative;
	width: 100%;
}

.axs-md-accordion-input {
	display: none;
}

.axs-md-accordion-label {
	cursor: pointer;
	display: table;
	margin-bottom: 0;
	padding: 18px 50px 18px 0;
	position: relative;
	width: 100%;
}

.axs-md-accordion-label span {
	display: table-cell;
	vertical-align: middle;
	width: 84%;
}

.axs-md-accordion-label svg {
	position: absolute;
	right: 6px;
	transform: rotate(90deg);
}

.axs-md-accordion-content {
	box-sizing: border-box;
	margin: 0;
	max-height: 0;
	overflow: hidden;
	transition: all .1s ease-out;
	width: 84%;
}

.axs-md-accordion-input:checked ~ .axs-md-accordion-content {
	margin: 12px 0 30px;
	max-height: 1500px;
}

.axs-md-accordion-input:checked + .axs-md-accordion-label {
	font-family: AudiTypeBold, Verdana, sans-serif;
}

.axs-md-accordion-input:checked + .axs-md-accordion-label svg {
	transform: rotate(90deg) scaleX(-1);
}

@media all and (min-width: 1440px) {
	.axs-md-accordion-input:checked ~ .axs-md-accordion-content {
		margin: 18px 0 36px;
	}
}

@media all and (min-width: 1920px) {
	.axs-md-accordion-input:checked ~ .axs-md-accordion-content {
		margin: 24px 0 42px;
	}
}
