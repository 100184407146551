.axs-md-stories svg {
	pointer-events: initial;
}

.axs-md-stories {
	position: relative;
}

input[name$="storiesinput"],
input[name$="storiesinputoverview"],
input[name$="expanddetailview"] {
	display: none;
}

.axs-md-stories-details-element .axs-md-stories-img {
	float: left;
	margin-bottom: 20px;
	width: 100%;
}

.axs-md-stories-details-element .axs-md-stories-img-169 {
	display: none;
}

.axs-md-stories-unfolder-radio {
	cursor: pointer;
}

.axs-md-stories-bild-text,
.axs-md-stories-video-text {
	margin-bottom: 15px;
}

.axs-md-stories-subheadline-label {
	margin-bottom: 10px;
	margin-top: 8px;
}

.axs-md-stories-text p {
	font-size: 1.4rem;
	line-height: 2.4rem;
	margin-bottom: 20px;
}

.axs-md-stories-interviewitem {
	margin-bottom: 37px;
	margin-top: 10px;
}

.axs-md-stories-question {
	margin-bottom: 7px;
}

/* DESKTOP XL */

@media all and (min-width: 1281px) {
	.axs-md-stories-desktop-story-view {
		display: none;
		width: 100%;
	}

	.axs-md-stories-details {
		border-right: 1px solid #d0d3d4;
		float: left;
		padding-right: 3%;
		width: 67% !important;
	}

	.axs-md-stories-desktop-stories-overview {
		float: right;
		width: 30% !important;
	}
}

/* DESKTOP */

@media all and (min-width: 1025px) {
	.axs-md-stories-mobile-view {
		display: none;
	}

	.axs-md-stories-desktop-view {
		display: block;
	}

	.axs-md-stories-thumbnail-key-visual {
		float: left;
		height: 22.5%;
		width: 40%;
	}

	.axs-md-stories-desktop-stories-overview {
		float: right;
		max-height: 500px;
		overflow: auto;
		width: 39%;
	}

	.axs-md-stories-infos {
		width: 60%;
	}

	.axs-md-stories-infos,
	.axs-md-stories-desktop-stories-overview-left {
		float: left;
	}

	.axs-md-stories-headline-subline {
		margin-left: 15px;
		margin-top: 25px;
	}

	.axs-md-stories-headline {
		margin-bottom: 5px;
	}

	.axs-md-stories-details {
		float: left;
		width: 58%;
	}

	.axs-md-stories-details-left {
		border-right: 1px solid #d0d3d4;
		padding-right: 3%;
	}

	.axs-md-stories-details-right {
		border-left: 1px solid #d0d3d4;
		float: right;
		padding-left: 3%;
	}

	.axs-md-stories-headline-desktop-detail-view {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.axs-md-stories-overview-element {
		border-left: 2px solid var(--color-white);
		border-top: 1px solid #d0d3d4;
		cursor: pointer;
		list-style: none;
		overflow: hidden;
		width: 100%;
	}

	.axs-md-stories-overview-element:last-child {
		border-bottom: 1px solid #d0d3d4;
	}

	.axs-md-stories-overview-headline {
		margin-bottom: 15px;
	}

	.axs-md-stories-desktop-story-view {
		display: none;
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-desktop-story-view {
		display: block;
	}

	label[for$="minimize"] {
		display: none;
	}

	input[id$="expand"]:checked ~ .axs-md-stories-desktop-stories-overview {
		display: none;
	}

	input[id$="expand"]:checked ~ .axs-md-stories-details {
		border-right: none;
		padding-right: 0;
		width: 100% !important;
	}

	input[id$="expand"]:checked ~ .axs-md-stories-details label[for$="expand"],
	input[id$="minimize"]:checked ~ .axs-md-stories-details label[for$="minimize"] {
		display: none;
	}

	input[id$="expand"]:checked ~ .axs-md-stories-details label[for$="minimize"],
	input[id$="minimize"]:checked ~ .axs-md-stories-details label[for$="expand"] {
		display: block;
	}

	input[id$="expand"]:checked ~ .axs-md-stories-details .axs-md-stories-subheadline-label,
	input[id$="expand"]:checked ~ .axs-md-stories-details .axs-md-stories-text {
		width: 83%;
	}

	input[name$="storiesinputoverview"]:checked + .axs-md-stories-overview-element {
		background-color: #e7e9ea;
		border-left: 2px solid #c03;
	}

	.axs-md-stories-expand-button svg {
		cursor: pointer;
		display: block;
		float: right;
		height: var(--icon-size-small);
		padding: 0;
		position: relative;
		width: var(--icon-size-small);
	}

	.axs-md-stories-expand-button-icon::before {
		background: center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTcxRjU5QzhBMDFCMTFFNUI3MEE5NDQyNkI4RkRBNTciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTcxRjU5QzdBMDFCMTFFNUI3MEE5NDQyNkI4RkRBNTciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxQTdDQTc0NDYwQUQxMUUxODk0NkIwRTkzQ0JEM0Y5NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxQTdDQTc0NTYwQUQxMUUxODk0NkIwRTkzQ0JEM0Y5NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pme9hP4AAADDSURBVHjaYvz//z/DQAImhgEGow4YdcCwc0ADEP/HgbEDUDmABxsA8Yz/+AGyegE0PB+HOjgm5ACQ5c+wGIyMcenth1rsQIkD/hOwBBeG+TwBqp+uDkC2nIHeDkC3nK4OwGY5zAEOuPQxEqgNQZKCRGS/+UAcAMSJQLyAlHzLQoSa90So+QB16AdSCw5iHECMwf8Ha1EsQMhxo5XRqANYSEhM5Ki7BsQz6VEO4FIHsvwEJQ5gHE2Eow4YdcCwdwBAgAEAYMx3VxI7WycAAAAASUVORK5CYII=") no-repeat var(--color-black);
		margin-top: -10px;
	}

	.axs-md-stories-close-button-icon::before {
		background: center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjBCM0Q0NTM4OUEzMTFFMkFCQzNCMDIwMUU3RTM5NUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjBCM0Q0NTQ4OUEzMTFFMkFCQzNCMDIwMUU3RTM5NUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MEIzRDQ1MTg5QTMxMUUyQUJDM0IwMjAxRTdFMzk1QiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2MEIzRDQ1Mjg5QTMxMUUyQUJDM0IwMjAxRTdFMzk1QiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgeD5egAAADJSURBVHja7NhBCsMgEAXQmdKDuErX3sgbu+5Kb+ARrCMEQjDVmkps+QNDRIQ8EvyYcIxxoYnrRpMXgH8PvJcmmfl5BSZt2AdeMYAAAgjgl4ApVMkYU10na2TtmfRe9i3TtU43jlJyPbNm20VLL7AG+BQ3BHgE6cENA25BSqncPbgjIJeO/D2nmQQja20ea63Je4/TTHNJlDjn8pOTlnFLBA2NmZ/YJAIIIcwZM9MHdeuNV+TlMYOPJgABBBDAMcX4BQwggO/rJcAAb9Zpo3ngepEAAAAASUVORK5CYII=") no-repeat;
	}

	.axs-md-stories-subheadline-label {
		margin-bottom: 10px;
	}

}

/* TABLET AND DESKTOP */

@media all and (min-width: 769px) {
	.axs-md-stories-two-columns {
		float: left;
		margin-right: 4%;
		width: 48%;
	}

	.axs-md-stories-two-columns:nth-child(2n) {
		margin-right: 0%;
	}
}

/* TABLET */

@media all and (min-width: 769px) and (max-width: 1024px) {
	.axs-md-stories-details-element {
		width: 100%;
	}

	.axs-md-stories-thumbnail-key-visual {
		float: left;
		height: 12.5%;
		width: 22%;
	}

	.axs-md-stories-headline-subline {
		float: left;
		margin-left: 15px;
		margin-top: 3.7%;
	}

	.axs-md-stories-unfolder-radio::before {
		margin-top: 3.7%;
	}

	.axs-md-stories-unfolder-radio svg {
		margin-top: 2%;
	}

}

/* MOBILE */

@media all and (max-width: 768px) {
	.axs-md-stories-thumbnail-key-visual {
		display: none;
	}

	.axs-md-stories-headline-subline {
		float: left;
		margin-top: 20px;
	}

	.axs-md-stories-unfolder-radio::before {
		margin-top: 20px;
	}

	.axs-md-stories-unfolder-radio svg {
		margin-top: 10px;
	}

	.axs-md-stories-details-element .axs-md-stories-img-165 {
		display: none;
	}

	.axs-md-stories-details-element .axs-md-stories-img-169 {
		display: block;
	}
}

/* MOBILE  and TABLET */

@media all and (max-width: 1024px) {
	.axs-md-stories-desktop-view {
		display: none;
	}

	.axs-md-stories-mobile-view {
		display: block;
	}

	.axs-md-stories-details-element {
		border-top: 1px solid #d0d3d4;
		list-style: none;
		min-height: 80px;
		overflow: hidden;
		width: 100%;
	}

	.axs-md-stories-details-element:last-child {
		border-bottom: 1px solid #d0d3d4;
	}

	.axs-md-stories-headline {
		margin-bottom: 10px;
	}

	.radio0 {
		display: none;
	}

	.axs-md-stories-detail {
		clear: both;
		display: none;
		overflow: hidden;
	}

	.axs-md-stories-unfolder-radio svg {
		cursor: pointer;
		display: block;
		float: right;
		height: var(--icon-size-large);
		position: relative;
		width: var(--icon-size-large);
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-infos .axs-md-stories-unfolder-radio {
		display: none;
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-infos .radio0 {
		display: block;
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-infos .axs-md-stories-unfolder-radio::before {
		background: center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAYAAABi8KSDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4QTQ0QTBCNkYwQjIxMUU0OUQxQkM5Qjg4ODExNjRCMiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4QTQ0QTBCN0YwQjIxMUU0OUQxQkM5Qjg4ODExNjRCMiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjc4MjAyRkZERjBCMjExRTQ5RDFCQzlCODg4MTE2NEIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjc4MjAyRkZFRjBCMjExRTQ5RDFCQzlCODg4MTE2NEIyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+wtWy+wAAALBJREFUeNp80D0KAjEQhuFEg+B57DyGJ7AQ1MZ+1Ub8uYCFnWDpIt7Cbu+ihT8gq+s78lm4RAce2J1MJpP4zDVcKTwKF4lK6b+KgGZk7SthRQ8kOGAYKzY15BhhorWpNn5GexfWcccYMxyxxAkL5e0OwYqvSlinC3oYoIuz8jZSHnS0JW7oINXRqY5fY27fnqcr1LGNXeTFWtjYuEEz9n8UWux1t1VQ4db9D2v0fAkwACq9JXO6Q98RAAAAAElFTkSuQmCC") no-repeat #e7e9ea;
		margin-bottom: 20px;
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-infos .axs-md-stories-unfolder-radio svg {
		transform: rotate(180deg);
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-headline {
		margin-top: 15px;
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-detail {
		display: block;
		padding-bottom: 40px;
	}

	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-subline-label,
	input[name$="storiesinput"]:checked ~ .axs-md-stories-mobile-view .axs-md-stories-thumbnail-key-visual {
		display: none;
	}
}

.axs-md-stories .axs-md-story-content-item.axs-md-story-content-item-double-column {
	column-count: 2;
	column-gap: calc(100% / 23);
	margin-bottom: 20px;
	width: 100%;
}

.axs-md-stories .axs-md-story-content-item.axs-md-story-content-item-double-column .axs-md-stories-img-169 {
	display: block;
}

.axs-md-stories .axs-md-story-content-item.axs-md-story-content-item-double-column .axs-md-stories-img-165 {
	display: none;
}
