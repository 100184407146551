.axs-md-app-module .axs-inner-content {
	position: relative;
}

.axs-md-app-module-slide {
	display: flex;
	height: 416px;
	justify-content: space-between;
}

.axs-md-app-module .axs-at-slider {
	bottom: -24px;
	line-height: 0;
}

.axs-md-app-module .axs-at-hdl-u::after {
	margin-bottom: 0;
}

.axs-md-app-module-slide-content p {
	margin-bottom: 40px;
}

.axs-md-app-module-slide-image {
	align-self: center;
	display: none;
}

.axs-md-app-module-slide-image img {
	display: none;
}

.axs-md-app-module-applinks {
	bottom: 0;
	line-height: 0;
	position: absolute;
}

.axs-md-app-module-applinks a {
	display: inline-block;
	margin-right: 18px;
}

input[type=radio].axs-at-slider-input + .axs-md-app-module-slide {
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: opacity 2s;
	visibility: hidden;
}

input[type=radio].axs-at-slider-input:checked + .axs-md-app-module-slide {
	height: 416px;
	opacity: 1;
	overflow: auto;
	visibility: visible;
}

@media all and (min-width: 768px) {
	.axs-md-app-module .axs-at-slider {
		bottom: -30px;
	}

	.axs-md-app-module-slide {
		padding-left: 12px;
		padding-right: 12px;
	}

	.axs-md-app-module-slide-content {
		width: 56%;
	}

	.axs-md-app-module-slide-content.axs-md-app-module-slide-image {
		width: 40%;
	}

	.axs-md-app-module-slide-image,
	.axs-md-app-module-slide-image img {
		display: block;
	}

	.axs-md-app-module-slide-image img {
		margin: 0 auto;
		max-height: 416px;
		max-width: 100%;
		padding: 0 25px;
	}
}

@media all and (min-width: 1024px) {
	.axs-md-app-module .axs-at-slider {
		bottom: -42px;
	}

	.axs-md-app-module-slide-content {
		width: 48%;
	}

	.axs-md-app-module-slide-content.axs-md-app-module-slide-image {
		width: 48%;
	}
}

@media all and (min-width: 1440px) {
	.axs-md-app-module .axs-at-slider {
		bottom: -60px;
	}
}

@media all and (min-width: 1920px) {
	.axs-md-app-module .axs-at-slider {
		bottom: -78px;
	}
}
