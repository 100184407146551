.axs-ondemand-configurator__modelfilter {
	display: block;
	padding: 0 4%;
}

.axs-ondemand-configurator__modelfilter-filterbar {
	font-size: 0; /* represses some nasty spaces */
}

.axs-ondemand-configurator__modelfilter-dropdown {
	display: block;
	margin-bottom: var(--space-s);
	max-width: 340px;
	position: relative;
	z-index: 52;
}

.axs-ondemand-configurator__modelfilter-dropdown-selection-container {
	line-height: 0; /* fixes alignment of label and dropdown */
}

.axs-ondemand-configurator__modelfilter-dropdown:last-of-type {
	margin-bottom: 0;
}

.axs-ondemand-configurator__modelfilter-dropdown-selection {
	appearance: none;
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid var(--color-black);
	border-radius: 0;
	color: var(--color-grey-70);
	cursor: pointer;
	outline: none;
	padding-bottom: var(--space-xs);
	position: relative;
	width: 100%;
}

.axs-ondemand-configurator__modelfilter-dropdown-selection:hover {
	color: var(--color-black);
}

.axs-ondemand-configurator__modelfilter-dropdown-label {
	color: var(--color-grey-50);
}

.axs-ondemand-configurator__modelfilter-dropdown .axs-ondemand-configurator__modelfilter-arrow-icon {
	bottom: 7px;
	position: absolute;
	right: 0;
}

.axs-ondemand-configurator__modelfilter-dropdown-list {
	background-color: var(--color-grey-05);
	left: 0;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	padding: 0 var(--space-s);
	position: absolute;
	top: 100%;
	transition: all var(--time-xl) var(--transition-ease-in);
	width: 100%;
	z-index: 1;
}

.axs-ondemand-configurator__modelfilter-flyout-list {
	background-color: var(--color-white);
	display: flex;
	flex-flow: row wrap;
	max-height: 0px;
	opacity: 0;
	overflow: hidden;
	transition: all var(--time-xl) var(--transition-ease-in);
	width: 100%;
	z-index: 1;
}

.axs-ondemand-configurator__modelfilter-dropdown-item {
	border-bottom: 1px dotted var(--color-white);
	color: var(--color-grey-60);
	cursor: pointer;
	padding: var(--space-s) 0;
}

.axs-ondemand-configurator__modelfilter-flyout-item {
	display: inline-block;
	margin-bottom: var(--space-s);
	text-align: center;
	width: calc(50% - var(--space-xxl) / 2);
}

.axs-ondemand-configurator__modelfilter-flyout-item:nth-child(2n) {
	margin-left: var(--space-xxl);
}

.axs-ondemand-configurator__modelfilter-flyout-image {
	width: 100%;
}

.axs-ondemand-configurator__modelfilter-flyout-checkbox {
	display: inline-block;
	margin-top: var(--space-s);
}

.axs-ondemand-configurator__modelfilter-dropdown-item:last-of-type {
	border-bottom: 0;
}

.axs-ondemand-configurator__modelfilter-dropdown-item-selected {
	color: var(--color-black);
}

.axs-ondemand-configurator__modelfilter-dropdown-value {
	color: inherit;
	display: block;
}

[is-open="true"].axs-ondemand-configurator__modelfilter-dropdown .axs-ondemand-configurator__modelfilter-dropdown-list {
	max-height: 2000px;
	opacity: 1;
	transition: all var(--time-xl) var(--transition-ease-in);
}

[is-open="true"].axs-ondemand-configurator__modelfilter-dropdown + .axs-ondemand-configurator__modelfilter-flyout .axs-ondemand-configurator__modelfilter-flyout-list {
	max-height: 2000px;
	opacity: 1;
	transition: all var(--time-xl) var(--transition-ease-in);
}

[is-open="true"].axs-ondemand-configurator__modelfilter-dropdown .axs-ondemand-configurator__modelfilter-dropdown-selection {
	border-width: 2px;
	color: var(--color-black);
	padding-bottom: calc(var(--space-xs) - 1px); /* -1px because the border got bigger by 1px and the dropdown should not jump */
}

[is-open="true"].axs-ondemand-configurator__modelfilter-dropdown .axs-ondemand-configurator__modelfilter-arrow-icon {
	transform: rotate(180deg);
}

[dropdown-id="sort"].axs-ondemand-configurator__modelfilter-dropdown {
	z-index: 51;
}

[dropdown-id="bodytype"].axs-ondemand-configurator__modelfilter-dropdown {
	z-index: 50;
}

.axs-ondemand-configurator--no-results [dropdown-id="bodytype"] .axs-ondemand-configurator__modelfilter-dropdown-selection-value {
	color: var(--color-red);
}

[is-mobile="false"] .axs-ondemand-configurator__modelfilter-dropdown-select,
[is-mobile="false"] .axs-ondemand-configurator__modelfilter-dropdown-selection-label {
	display: none;
}

[is-mobile="true"] .axs-ondemand-configurator__modelfilter-dropdown-custom,
[is-mobile="true"] .axs-ondemand-configurator__modelfilter-dropdown-custom-label {
	display: none;
}

[is-mobile="true"][is-open="true"].axs-ondemand-configurator__modelfilter-dropdown .axs-ondemand-configurator__modelfilter-dropdown-list {
	max-height: 0;
	opacity: 0;
}

@media all and (min-width: 480px) {
	.axs-ondemand-configurator__modelfilter {
		padding-bottom: var(--space-m);
	}

	.axs-ondemand-configurator__modelfilter-flyout-item:nth-child(n) {
		margin-left: var(--space-xxl);
		width: calc(100% / 3 - ((var(--space-xxl) / 3) * 2));
	}

	.axs-ondemand-configurator__modelfilter-flyout-item:nth-child(3n - 2) {
		margin-left: 0;
	}
}

@media all and (min-width: 768px) {
	.axs-ondemand-configurator__modelfilter-flyout-item:nth-child(n) {
		margin-left: var(--space-xxl);
		width: calc(25% - ((var(--space-xxl) / 4) * 3));
	}

	.axs-ondemand-configurator .axs-ondemand-configurator__modelfilter-flyout-item:nth-child(4n - 3) { /* increased specificity, because of variable XXL media queries */
		margin-left: 0;
	}
}

@media all and (min-width: 1173px) {
	.axs-ondemand-configurator__modelfilter-dropdown {
		display: inline-block;
		margin-bottom: 0;
		margin-right: var(--space-l);
		max-width: inherit;
		width: calc((100% / 3) - (2 * var(--space-l) / 3));
	}

	.axs-ondemand-configurator__modelfilter-dropdown:nth-of-type(3) { /* :last-of-type does not work for ie11 */
		margin-right: 0;
	}
}
