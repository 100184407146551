/*!
 * #*VERSION-START*#18.61.0#*VERSION-END*#
 */
@charset "UTF-8";

/* basic variables from core-ci */
@import "audi-core-ci-reset-and-basic.min.css"; /* hint: source path is defined in postcss.config.js */
@import "audi-variables.css"; /* hint: source path is defined in postcss.config.js */

/* basic grid variables from grid */
@import "../application/grid/grid.vars.css";

/* editorial */
@import "../../../../components/accordion/accordion.css";
@import "../../../../components/anchor-navigation/anchor-navigation.css";
@import "../../../../components/app-module/app-module.css";
@import "../../../../components/audicity/audicity.css";
@import "../../../../components/base-accordion/base-accordion.css";
@import "../../../../components/base-caroussel/base-caroussel.css";
@import "../../../../components/button-combined/button-combined.css";
@import "../../../../components/base-table/base-table.css";
@import "../../../../components/configurator-flat/configurator-flat.css";
@import "../../../../components/configurator-ondemand/configurator-ondemand.css";
@import "../../../../components/country-selector/country-selector.css";
@import "../../../../components/dealersearch/dsh.css";
@import "../../../../components/dealersearch/dsh-form.css";
@import "../../../../components/editorial-teaser/editorial-teaser.css";
@import "../../../../components/editorial/editorial.css";
@import "../../../../components/faq/faq.css";
@import "../../../../components/faq-searchable/faq-searchable.css";
@import "../../../../components/headline-module/headline-module.css";
@import "../../../../components/hub-search/hub-search.css";
@import "../../../../components/icon-text-condition/icon-text-condition.css";
@import "../../../../components/icon-text-highlight/icon-text-highlight.css";
@import "../../../../components/iframe/iframe.css";
@import "../../../../components/info-module/info-module.css";
@import "../../../../components/keepmeinformed/keepmeinformed.css";
@import "../../../../components/languageswitch/languageswitch.css";
@import "../../../../components/map/map.css";
@import "../../../../components/multi-quotes/multi-quotes.css";
@import "../../../../components/packageinformation/packageinformation.css";
@import "../../../../components/social/social.css";
@import "../../../../components/stage/stage.css";
@import "../../../../components/stories/stories.css";
@import "../../../../components/thumbnail-text/thumbnail-text.css";
@import "../../../../components/tooltip/tooltip.css";
@import "../../../../components/usp-teaser/usp-teaser.css";
