
.axs-md-socialreader-item {
	border: 1px solid var(--color-grey-30);
	/* stylelint-disable property-no-vendor-prefix */
	-webkit-column-break-inside: avoid;
	/* stylelint-enable property-no-vendor-prefix */
	margin-bottom: 30px;
	overflow: hidden;
	page-break-inside: avoid;
	width: 100%;
}

.axs-md-socialreader-item > a {
	color: var(--color-black);
}

.axs-md-socialreader-item img {
	width: 100%;
}

.axs-md-socialreader-headline {
	margin-bottom: 0;
}

.axs-md-socialreader-container {
	vertical-align: top;
	width: 100%;
}

.axs-md-socialreader-media {
	line-height: 0;
	min-height: 90px;
	position: relative;
}

.axs-md-socialreader-media .axs-button-overlay {
	background-color: rgba(0, 0, 0, .5);
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
}

.axs-md-socialreader-media .axs-button-overlay svg {
	fill: var(--color-white);
	left: calc(50% - var(--icon-size-large) / 2);
	position: absolute;
	top: calc(50% - var(--icon-size-large) / 2);
}

.axs-md-socialreader-text {
	padding: 18px 12px 0;
}

.axs-md-socialreader-text .audi-headline-order-3 {
	margin-bottom: 18px;
}

.axs-md-socialreader-via {
	align-items: center;
	display: flex;
	flex-flow: row wrap;
	padding: 18px 12px;
}

.axs-md-socialreader-infos {
	padding-left: 18px;
}

.axs-md-socialreader-infos span {
	color: var(--color-grey-30);
	display: block;
	overflow: hidden;
	transition: opacity .2s;
}

.axs-md-socialreader-gototext {
	max-height: 0;
	opacity: 0;
}

.axs-md-socialreader-via:hover {
	background-color: var(--color-black);
}

.axs-md-socialreader-via:hover span {
	color: var(--color-white);
	max-height: 0;
}

.axs-md-socialreader-via:hover .axs-md-socialreader-gototext {
	max-height: 30px;
	opacity: 1;
}

.axs-md-socialreader-via:hover svg {
	fill: var(--color-white);
}

@media all and (min-width: 480px) {
	.axs-md-socialreader-container {
		column-count: 2;
		column-gap: 36px;
	}
}

@media all and (min-width: 768px) {
	.axs-md-socialreader-headline {
		padding-bottom: 48px;
	}

	.axs-md-socialreader-container {
		column-count: 3;
		column-gap: 36px;
	}
}

@media all and (min-width: 1024px) {
	.axs-md-socialreader-headline {
		padding-bottom: 60px;
	}

	.axs-md-socialreader-container {
		column-count: 4;
		column-gap: 42px;
	}
}

@media all and (min-width: 1440px) {
	.axs-md-socialreader-headline {
		padding-bottom: 78px;
	}

	.axs-md-socialreader-container {
		column-gap: 54px;
	}

	.axs-md-socialreader-item {
		margin-bottom: 36px;
	}

}

@media all and (min-width: 1920px) {
	.axs-md-socialreader-headline {
		padding-bottom: 96px;
	}

	.axs-md-socialreader-container {
		column-gap: 66px;
	}

	.axs-md-socialreader-item {
		margin-bottom: 42px;
	}
}
