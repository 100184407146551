.axs-module-anchor-navigation {
	text-align: center;
	z-index: 6;
}

.axs-module-anchor-navigation-container {
	background-color: var(--color-grey-05);
	padding-bottom: var(--space-l);
	padding-top: var(--space-l);
}

.axs-module-anchor-navigation-wrapper {
	display: flex;
	overflow: hidden;
	position: relative;
}

.axs-module-anchor-navigation-list {
	margin-left: auto;
	margin-right: auto;
	transition: max-height 1s var(--transition-ease-in) var(--time-s);
	white-space: nowrap;
}

.axs-module-anchor-navigation-list-item {
	display: inline-block;
	margin-right: var(--space-xxl);
}

.axs-module-anchor-navigation-list-item:last-of-type {
	margin-right: 0;
}

[is-burger-menu="false"] .axs-module-anchor-navigation-list-item::after {
	background: var(--color-black);
	bottom: .85rem; /* close to text base line */
	margin-bottom: -6px;
	margin-top: 6px;
	position: relative;
	transition: width var(--time-xl) var(--transition-ease-in-out) var(--time-s);
	width: 0;
}

[is-burger-menu="false"] .axs-module-anchor-navigation-list-item-is-active::after {
	width: 100%;
}

.axs-module-anchor-navigation-link {
	display: inline-block;
}

.axs-module-anchor-navigation-list-item:hover .axs-module-anchor-navigation-link {
	color: var(--color-grey-70);
}

.axs-module-anchor-navigation-anchor {
	display: block;
}

.axs-module-anchor-navigation-flyout-button-container {
	display: none;
	left: 0;
	margin: 0 auto;
	position: absolute;
	top: 0;
	transition: opacity var(--time-s) var(--transition-ease-out);
	width: 100%;
}

.axs-module-anchor-navigation-flyout-button-text {
	color: var(--color-black);
	font-weight: var(--font-weight-bold);
}

.axs-module-anchor-navigation-flyout-open-icon {
	display: block;
	height: var(--icon-size-small);
	margin-top: 6px;
}

.axs-module-anchor-navigation-flyout-button .audi-down-small {
	height: var(--icon-size-small);
	width: var(--icon-size-small);
}

.axs-module-anchor-navigation-list-item::after {
	background-color: var(--color-grey-20);
	content: "";
	display: block;
	height: 1px;
	margin: 0 auto;
	transition: width var(--time-xs) var(--transition-ease-out) var(--time-xs);
	width: 0;
}

.axs-module-anchor-navigation-list-item:first-of-type::after {
	background-color: var(--color-black);
}

/* sticky navigation */

[is-sticky="true"].axs-module-anchor-navigation {
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}

/* burger menu */
[is-burger-menu="true"].axs-module-anchor-navigation {
	padding-bottom: var(--space-xs);
	padding-top: var(--space-l);
	position: static;
}

[is-burger-menu="true"] .axs-module-anchor-navigation-list {
	height: auto;
	max-height: 1500px;
}

[is-burger-menu="true"] .axs-module-anchor-navigation-list-item {
	display: block;
	margin: 0;
}

[is-burger-menu="true"] .axs-module-anchor-navigation-link {
	color: var(--color-grey-70);
}

[is-burger-menu="true"] .axs-module-anchor-navigation-link:hover {
	color: var(--color-black);
}

[is-burger-menu="true"] .axs-module-anchor-navigation-list-item:first-of-type .axs-module-anchor-navigation-link {
	color: var(--color-black);
	font-weight: var(--font-weight-bold);
}

[is-burger-menu="true"] .axs-module-anchor-navigation-flyout-button-container {
	display: block;
}

[is-burger-menu="true"][is-flyout-open="true"] .axs-module-anchor-navigation-list-item::after {
	margin: var(--space-m) auto;
	width: 30px;
}

[is-burger-menu="true"][is-flyout-open="true"] .axs-module-anchor-navigation-list-item:last-of-type::after {
	margin-bottom: 0;
}

[is-burger-menu="true"][is-flyout-open="true"] .axs-module-anchor-navigation-flyout-button-container {
	opacity: 0;
	pointer-events: none;
}

/* burger menu flyout closed */

[is-burger-menu="true"][is-flyout-open="false"] .axs-module-anchor-navigation-container {
	padding-bottom: var(--space-xs);
}

[is-burger-menu="true"][is-flyout-open="false"] .axs-module-anchor-navigation-list {
	height: 54px; /* label (audi-copy-m) + space-xs + icon-height */
	max-height: 54px;
}

[is-burger-menu="true"][is-flyout-open="false"] .axs-module-anchor-navigation-list-item {
	display: none;
}

[is-burger-menu="true"][is-flyout-open="false"] .axs-module-anchor-navigation-list-item:first-child {
	display: block;
}

[is-burger-menu="true"][is-flyout-open="false"] .axs-module-anchor-navigation-list-item:first-child .axs-module-anchor-navigation-link {
	display: none;
}
