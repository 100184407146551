/* language switch */

.axs-header-menu-shader {
	display: none;
}

.axs-header-menu-subnav .axs-el-hdl.axs-el-hdl-02.axs-at-hdl-b {
	display: none;
}

.axs-header-menu-subnav .axs-header-menu-subitem .axs-el-lk-nav-02 {
	background-color: var(--color-grey-10);
	border-bottom: 1px solid #d0d3d4;
	color: var(--color-grey-70);
	display: block;
	font-family: AudiTypeNormal, sans-serif;
	font-size: 1.4rem;
	line-height: 1.5rem;
	margin-left: 50px;
	margin-right: 25px;
	padding: 22px 0;
	width: calc(100% - 75px);
}

#axs-md-header-sub-nav-toggle-language-switcher ~ .axs-md-header .axs-header-menu-layer {
	display: none;
}

#axs-md-header-sub-nav-toggle-language-switcher:checked ~ .axs-md-header .axs-header-menu-layer {
	display: block;
}

.axs-layer-btn-close {
	display: none;
}

#axs-md-header-sub-nav-toggle-language-switcher ~ .axs-md-header label span::after {
	content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEycHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgMTIgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMzkgKDMxNjY3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5mb3J3YXJkLXNtYWxsPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlsaW5lIGlkPSJmb3J3YXJkLXNtYWxsIiBmaWxsPSIjMDAwMDAwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDEzLjAwMDAwMCkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMy4wMDAwMDAsIC0xMy4wMDAwMDApICIgcG9pbnRzPSIwLjcwNCAyNiAwIDI1LjI5IDUuMTI3IDIwLjIwMyAwLjAwNSAxNS4xMjIgMC43MDkgMTQuNDEyIDYuNTQ3IDIwLjIwMyAwLjcwNCAyNiI+PC9wb2x5bGluZT4KICAgIDwvZz4KPC9zdmc+");
	margin-left: 7px;
}

#axs-md-header-sub-nav-toggle-language-switcher:checked ~ .axs-md-header label span::after {
	content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEycHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgMTIgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMzkgKDMxNjY3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5mb3J3YXJkLXNtYWxsPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlsaW5lIGlkPSJmb3J3YXJkLXNtYWxsIiBmaWxsPSIjMDAwMDAwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIC02LjAwMDAwMCkgc2NhbGUoMSwgLTEpIHJvdGF0ZSg5MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTMuMDAwMDAwLCA2LjAwMDAwMCkgIiBwb2ludHM9IjAuNzA0IDcgMCA2LjI5IDUuMTI3IDEuMjAzIDAuMDA1IC0zLjg3OCAwLjcwOSAtNC41ODggNi41NDcgMS4yMDMgMC43MDQgNyI+PC9wb2x5bGluZT4KICAgIDwvZz4KPC9zdmc+");
	margin-left: 7px;
}

.axs-header-menu-subnav .axs-header-menu-subitem.selected .axs-el-lk-nav-02,
.axs-header-menu-subnav .axs-header-menu-subitem .axs-el-lk-nav-02:hover {
	color: var(--color-black);
}

.axs-header-menu-subnav .axs-header-menu-subitem.selected .axs-el-lk-nav-02 .axs-language-switch-language-text::after {
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHZpZXdCb3g9IjAgMCAzMCAzMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pUGFkIFBybyBQb3J0cmFpdDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImlQYWQtUHJvLVBvcnRyYWl0IiBmaWxsPSIjMDAwMDAwIj4gICAgICAgICAgICA8cG9seWxpbmUgaWQ9InNlbGVjdC1zbWFsbCIgcG9pbnRzPSIxMi43MTcgMjAuMzMzIDggMTUuNzAxIDguNzAyIDE0Ljk4NyAxMi42MjQgMTguODQxIDIwLjQxOSA5IDIxLjIwMyA5LjYyMSAxMi43MTcgMjAuMzMzIj48L3BvbHlsaW5lPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+");
	background-position: center center;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	height: 14px;
	margin-left: 10px;
	width: 14px;
}

@media only all and (min-width: 768px) {
	.axs-header-menu-shader-wrapper {
		margin: 0 auto;
		max-width: 1280px;
		padding-top: 20px;
	}

	#axs-md-header-sub-nav-toggle-language-switcher ~ .axs-header-menu-shader {
		display: block;
		opacity: 0;
		transition: visibility 0s .2s, opacity .2s linear;
		/* display: none; */
		visibility: hidden;
	}

	#axs-md-header-sub-nav-toggle-language-switcher:checked ~ .axs-header-menu-shader {
		opacity: 1;
		transition: opacity .2s linear;
		/* display: block; */
		visibility: visible;
	}

	.axs-header-menu-shader {
		background-color: rgba(0, 0, 0, .8);
		height: 100%;
		margin-top: 78px;
		position: fixed;
		width: 100%;
		z-index: 3;
	}

	.axs-layer-btn-close {
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzAgMzAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjx0aXRsZT5jYW5jZWwteHhsPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48cGF0aCBpZD0iTGluZSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBkPSJNMjkuNCwyOS40TDAuNSwwLjUiLz48cGF0aCBpZD0iTGluZV8xXyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBkPSJNMC42LDI5LjRMMjkuNSwwLjUiLz48L3N2Zz4=");
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		display: block;
		float: right;
		height: 30px;
		margin: 0 4%;
		width: 30px;
	}

	.axs-header-menu-layer .axs-el-hdl.axs-el-hdl-02.axs-at-hdl-b {
		display: block;
		margin-bottom: 50px;
		white-space: initial;
	}

	.axs-header-menu-subnav.axs-header-menu-layer {
		background: var(--color-white);
		box-sizing: border-box;
		left: 25vw;
		margin-top: 100px;
		padding: 4.5%;
		position: fixed;
		width: 48vw;
	}

	.axs-md-header-menu li:last-child .axs-md-header-main-nav-item {
		border-bottom: 0;
	}

	.axs-md-header-menu li.axs-is-special-link .axs-md-header-main-nav-item {
		border-bottom: 1px solid var(--color-grey-80);
	}

	.axs-header-menu-list-group {
		margin-bottom: 50px;
	}

	.axs-header-menu-list-group:last-of-type {
		margin-bottom: 0;
	}

	.axs-header-menu-subnav li {
		float: none;
		margin: 0;
	}

	.axs-header-menu-subnav .axs-header-menu-subitem .axs-el-lk-nav-02 {
		background: none;
		border: none;
		color: var(--color-grey-70);
		font-family: AudiTypeNormal, sans-serif;
		font-size: 1.4rem;
		height: 15px;
		margin-bottom: 30px;
		padding: 0;
	}

	.axs-header-menu-subnav .axs-header-menu-subitem:last-child .axs-el-lk-nav-02 {
		margin-bottom: 0;
	}

}

@media only all and (min-width: 1279px) {
	.axs-header-menu-shader-wrapper {
		padding: 20px 2.5% 0;
	}

	.axs-layer-btn-close {
		margin: 0;
	}
}
