/*******************************************************************

	.axs-ondemand-configurator__details
	mobile-first

*******************************************************************/

.axs-ondemand-configurator__details {
	display: block;
}

/* headline + subheadline */
.axs-ondemand-configurator__details-headline {
	font-weight: var(--font-weight-bold);
}

.axs-ondemand-configurator__details-subheadline {
	display: block;
	font-weight: var(--font-weight-normal);
	margin-top: var(--space-xs);
}

/* gallery */

.axs-ondemand-configurator__detail-gallery {
	padding-bottom: calc(var(--space-m) + 6px + var(--space-s)); /* for absolute positioned dot-navigation  (6px = dot item height) */
}

.axs-ondemand-configurator__details[data-first-image-loaded="false"] .axs-ondemand-configurator__detail-gallery {
	opacity: 0;
}

.axs-ondemand-configurator__details[data-first-image-loaded="true"] .axs-ondemand-configurator__detail-gallery {
	opacity: 1;
	transition: opacity var(--time-xl);
}

.axs-ondemand-configurator__detail-gallery--without-controls {
	padding-bottom: calc(var(--space-m));
}

.axs-ondemand-configurator__detail-gallery--without-controls .axs-ondemand-configurator__detail-gallery-item {
	display: block;
}

.axs-ondemand-configurator__detail-gallery-arrow-navigation {
	display: none;
}

.axs-ondemand-configurator__detail-gallery-dot-navigation {
	bottom: var(--space-m);
}

.axs-ondemand-configurator__detail-gallery-dot-navigation-item {
	height: 6px;
}

/* description */

.axs-ondemand-configurator__detail-price-description-wrapper {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	margin-bottom: var(--space-xxxl);
}

/* price/booking block */

.axs-ondemand-configurator__details-price-booking-wrapper {
	display: none;
}

[is-standalone=false] .axs-ondemand-configurator__details-price-booking-wrapper {
	display: block;
}

.axs-ondemand-configurator__details-price-label {
	display: block;
	flex: 1;
	margin-right: var(--space-m);
}

.axs-ondemand-configurator__details-price-value {
	display: block;
	flex: 1;
	font-weight: var(--font-weight-bold);
	text-align: right;
}

.axs-ondemand-configurator__details-price-option {
	display: none;
}

.axs-ondemand-configurator__details-price-option--selected {
	display: flex;
}

.axs-ondemand-configurator__details-button {
	display: block;
	max-width: 100%;
	width: 100%;
}

.axs-ondemand-configurator__details-price-option + .axs-ondemand-configurator__details-button {
	margin-top: var(--space-l);
}

.axs-ondemand-configurator__details-description {
	margin-top: var(--space-xxl);
}

/* accordion */
.axs-ondemand-configurator__accordion .audi-axs-table {
	padding: 0;
}

.axs-ondemand-configurator__accordion-content li {
	margin-bottom: 12px;
	padding-left: 16px;
	position: relative;
}

.axs-ondemand-configurator__accordion-content li::before {
	background: var(--color-black);
	border-radius: 4px;
	content: "";
	height: 4px;
	left: 0;
	position: absolute;
	top: 12px;
	width: 4px;
}

.axs-ondemand-configurator__accordion-content {
	margin: var(--space-xl);
}

.axs-ondemand-configurator__accordion-item-icon-wrapper {
	margin-left: var(--space-xl);
}

@media all and (min-width: 1024px) {
	/* gallery */
	.axs-ondemand-configurator__detail-gallery {
		padding-bottom: calc(var(--space-m) + 8px + var(--space-l)); /* for absolute positioned dot-navigation (8px = dot item height) */
	}

	.axs-ondemand-configurator__detail-gallery--without-controls {
		padding-bottom: var(--space-l);
	}

	.axs-ondemand-configurator__detail-gallery-arrow-navigation {
		display: block;
	}

	.axs-ondemand-configurator__detail-gallery-arrow-navigation-icon-wrapper {
		top: calc(50% - (var(--icon-size-large) / 2) - ((var(--space-m) + 8px + var(--space-l)) / 2)); /* the space for dot-navigation must be concerned! */
	}

	.axs-ondemand-configurator__detail-gallery-dot-navigation {
		bottom: var(--space-l);
	}

	.axs-ondemand-configurator__detail-gallery-dot-navigation-item {
		height: 8px;
	}

	/* description */
	.axs-ondemand-configurator__detail-price-description-wrapper {
		flex-direction: row;
		margin-top: var(--space-xxl);
	}

	.axs-ondemand-configurator__details-description {
		flex: 0 0 65%;
		margin-top: 0;
		max-width: 65%;
	}

	/* price/booking block */
	.axs-ondemand-configurator__details-price-booking-wrapper {
		flex: 0 0 33%;
		text-align: right;
	}

	.axs-ondemand-configurator__details-price-option--selected {
		display: block;
	}

	.axs-ondemand-configurator__details-price-label {
		margin-bottom: var(--space-s);
		margin-right: 0;
	}

	.axs-ondemand-configurator__details-button {
		display: inline-block;
		float: right;
		min-width: 0;
		width: auto;
	}

	.axs-ondemand-configurator__details-price-option + .axs-ondemand-configurator__details-button {
		margin-top: var(--space-xl);
	}
}

@media all and (min-width: 1024px) {
	.axs-ondemand-configurator__details-subheadline {
		margin-top: 0;
	}
}
