.axs-ondemand-configurator {
	display: block;
	padding: 24px 0 var(--space-xxl);
	width: 100%;
}

@media all and (min-width: 1024px) {
	.axs-ondemand-configurator {
		padding-top: var(--space-xxl);
	}
}

.axs-ondemand-configurator__inner-wrapper {
	padding-left: 4%;
	padding-right: 4%;
}

/* embedded detailview */

.axs-ondemand-configurator__embedded-detailview-content {
	border-top: 1px solid var(--color-grey-05);
	padding-top: var(--space-l);
}

.axs-ondemand-configurator__embedded-detailview-content:empty {
	border: none;
	margin-top: 0;
}

.axs-ondemand-configurator__embedded-detailview-button-wrapper {
	font-size: 0px;
	line-height: 0px;
	padding: var(--space-s) 0;
	text-align: center;
}

.axs-ondemand-configurator__embedded-detailview-button-wrapper--hidden {
	display: none;
}

.axs-ondemand-configurator__embedded-detailview-close {
	appearance: none;
	display: inline-block;
	height: var(--icon-size-small);
	margin: auto;
	width: var(--icon-size-small);
}

.axs-ondemand-configurator__embedded-detailview-close:hover {
	color: var(--color-grey-70);
}

.axs-ondemand-configurator__embedded-detailview-close-icon--large {
	display: none;
}

@media all and (min-width: 768px) {
	.axs-ondemand-configurator_embedded-detailview-outer-wrapper {
		position: relative;
	}
}

@media all and (min-width: 1024px) {
	.axs-ondemand-configurator__embedded-detailview-button-wrapper {
		margin-top: var(--space-xxl);
		padding: 0;
		position: absolute;
		right: 0;
		text-align: right;
		top: 0;
	}

	.axs-ondemand-configurator__embedded-detailview-close {
		height: var(--icon-size-large);
		width: var(--icon-size-large);
	}

	.axs-ondemand-configurator__embedded-detailview-close-icon--large {
		display: block;
	}

	.axs-ondemand-configurator__embedded-detailview-close-icon--small {
		display: none;
	}
}
