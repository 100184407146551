/* quick fix for multiple usp teaser below each other -> pls change me in the future! */
.axs-usp-teaser + .axs-module:not(.axs-usp-teaser):not(.axs-md-keepmeinformed) {
	margin-top: 0;
}

.axs-usp-teaser .axs-inner-content {
	position: relative;
	width: 100%;
	z-index: 5;
}

.axs-usp-teaser img {
	margin-bottom: 24px;
	width: 100%;
}

.axs-usp-teaser input[type=radio] {
	display: none;
}

.axs-usp-teaser input[type=radio] ~ .axs-usp-teaser__content-container label {
	background: none;
	cursor: pointer;
	display: none;
	font-family: AudiTypeNormal, Verdana, sans-serif;
	height: auto;
	opacity: 1;
	padding-right: 15px;
	width: auto;
}

.axs-usp-teaser input[type=radio] ~ .axs-usp-teaser__content-container label.selected {
	display: block;
	padding-left: 18px;
}

.axs-usp-teaser .axs-slider-content > ul {
	transition: none;
}

.axs-usp-teaser .axs-slider-content > ul li {
	height: 0;
	overflow: hidden;
	visibility: hidden;
}

.axs-usp-teaser__content-list li {
	opacity: 0;
	transition: opacity .3s cubic-bezier(.77, 0, .175, 1);
}

.axs-usp-teaser__slide {
	height: 0;
	overflow: hidden;
	visibility: hidden;
}

.axs-usp-teaser__content-list {
	border-top: 2px solid rgba(0, 0, 0, 1);
	margin-top: 12px;
	padding-top: 24px;
}

.axs-usp-teaser .axs-usp-teaser__button-wrapper a {
	display: inline-flex;
	height: 50px;
	padding: 0 30px;
}

.axs-usp-teaser h1 {
	margin: 0 0 12px;
}

.axs-usp-teaser p {
	margin-bottom: 0;
}

.axs-usp-teaser__content p:last-child {
	margin-bottom: 42px;
}

.axs-usp-teaser__button-wrapper {
	margin: 24px 0 42px;
}

.axs-usp-teaser__content-container {
	position: relative;
}

.axs-usp-teaser__arrow-navigation-button {
	color: var(--color-grey-30);
	height: var(--icon-size-small);
	margin: 0 -6px 0 0;
	position: absolute;
	top: -3px;
	width: var(--icon-size-small);
	z-index: 10;
}

.axs-usp-teaser__arrow-navigation-button--active {
	color: var(--color-black);
}

.axs-usp-teaser__arrow-navigation-button--prev {
	left: 4%;
	margin: 0 0 0 -6px;
	transform: scaleX(-1);
}

.axs-usp-teaser__arrow-navigation-button--next {
	right: 4%;
}

.axs-usp-teaser__media-item {
	background-color: var(--color-black);
	position: relative;
}

.axs-usp-teaser__media-item img {
	display: block;
}

@media all and (min-width: 768px) {
	.axs-usp-teaser input[type=radio] ~ .axs-usp-teaser__content-container label.selected,
	.axs-usp-teaser input[type=radio] ~ .axs-usp-teaser__content-container label {
		display: inline-block;
		padding-left: 0;
	}

	.axs-usp-teaser__arrow-navigation {
		display: none;
	}
}

@media all and (max-width: 1279px) {
	.axs-usp-teaser .axs-usp-teaser__content-container .axs-at-slider-input-label,
	.axs-usp-teaser .axs-usp-teaser__content-container .axs-at-slider-input-label:hover {
		color: var(--color-black);
	}

	/* force footnotes in mobile view */
	.axs-usp-teaser__slide[data-theme="dark"] sup[data-footnote] {
		color: var(--color-grey-70);
	}

	.axs-usp-teaser__slide[data-theme="dark"] sup[data-footnote]:hover,
	.axs-usp-teaser__slide[data-theme="dark"] sup[data-footnote]:active,
	.axs-usp-teaser__slide[data-theme="dark"] sup[data-footnote].active {
		color: var(--color-black) !important;
	}

	/* force normal buttons in mobile view */
	.axs-usp-teaser__slide[data-theme="dark"] .axs-button-standard,
	.axs-usp-teaser__slide[data-theme="dark"] .axs-button-standard:active,
	.axs-usp-teaser__slide[data-theme="dark"] .axs-button-standard[data-state="active"] {
		background-color: var(--color-black);
		color: var(--color-white);
	}

	.axs-usp-teaser__slide[data-theme="dark"] .axs-button-standard:hover,
	.axs-usp-teaser__slide[data-theme="dark"] .axs-button-standard:focus {
		background-color: var(--color-grey-80);
		color: var(--color-white);
	}

	.axs-usp-teaser__slide[data-theme="dark"] .axs-button-standard.inactive {
		background-color: var(--color-grey-30);
		color: var(--color-white);
	}
}

@media all and (min-width: 1280px) {
	.axs-usp-teaser {
		padding-bottom: 0;
	}

	.axs-usp-teaser__content-container {
		bottom: 0;
		position: absolute;
		width: 100%;
	}

	.axs-usp-teaser img {
		margin-bottom: 0;
	}

	.axs-usp-teaser [data-theme="dark"] h1,
	.axs-usp-teaser [data-theme="dark"] p,
	label.axs-link-navigation-2 {
		color: var(--color-white);
	}

	.axs-usp-teaser input[type=radio].black:checked ~ .axs-usp-teaser__content-container label {
		color: var(--color-black);
	}

	.axs-usp-teaser__content-list {
		border-top: 1px solid rgba(255, 255, 255, .5);
		max-width: 500px;
	}

	.axs-usp-teaser .axs-usp-teaser__content-container .axs-at-slider-input-label:hover {
		color: var(--color-white);
		opacity: .7;
	}

	.axs-usp-teaser input[type=radio].white:checked ~ .axs-usp-teaser__content-container .axs-usp-teaser__content-list {
		border-top: 2px solid rgba(255, 255, 255, .5);
		max-width: 640px;
	}

	.axs-usp-teaser input[type=radio].black:checked ~ .axs-usp-teaser__content-container .axs-usp-teaser__content-list {
		border-top: 2px solid rgba(0, 0, 0, .5);
		max-width: 640px;
	}

	.axs-usp-teaser input[type=radio] ~ .axs-usp-teaser__content-container label {
		opacity: .5;
	}
}
