.axs-editorial-teaser {
	padding-bottom: var(--space-xxxl);
	padding-top: var(--space-xxxl);
}

.axs-editorial-teaser[data-theme="dark"] {
	background-color: var(--color-black);
	color: var(--color-white);
}

/* margin between following modules should be var(--space-xxl) */
.axs-editorial-teaser + .axs-editorial-teaser {
	margin-top: calc(var(--space-xxl) - var(--space-xxxl));
	padding-top: 0;
}

.axs-editorial-teaser__item + .axs-editorial-teaser__item {
	margin-top: var(--space-xxl);
}

.axs-editorial-teaser__item-media {
	line-height: 0;
	margin-bottom: var(--space-l);
}

.axs-editorial-teaser__item-image {
	width: 100%;
}

.axs-editorial-teaser__item-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
}

.axs-editorial-teaser__item-copy p,
.axs-editorial-teaser__item-copy ul,
.axs-editorial-teaser__item-copy ol {
	margin-bottom: 15px;
}

.axs-editorial-teaser__item-copy ul {
	list-style-position: outside;
	list-style-type: disc;
}

.axs-editorial-teaser__item-copy ol {
	list-style-position: outside;
	list-style-type: decimal;
}

.axs-editorial-teaser__item-copy li {
	margin-left: 18px;
	padding-left: 10px;
}

.axs-editorial-teaser__item-linklist {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.axs-editorial-teaser__item-link-wrapper {
	margin-top: var(--space-l);
}

.axs-editorial-teaser__item-link-basic-wrapper + .axs-editorial-teaser__item-link-basic-wrapper {
	margin-top: var(--space-xxs);
}

.axs-editorial-teaser__item-play-button {
	display: inline-block;
	position: relative;
}

.axs-editorial-teaser__item-play-button::before {
	background-color: var(--color-black);
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAbCAYAAABMU775AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEU3MkE1REIzMTE4MTFFNDg2RUZDMzUwMzA4MDdEQzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEU3MkE1REMzMTE4MTFFNDg2RUZDMzUwMzA4MDdEQzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0RTcyQTVEOTMxMTgxMUU0ODZFRkMzNTAzMDgwN0RDNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0RTcyQTVEQTMxMTgxMUU0ODZFRkMzNTAzMDgwN0RDNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjccRngAAACQSURBVHjavJRLDoAgDERb4sl0qxu9/1nwEzCAQGdY2IRASB4dKB31V4iIChlTmDN42Q4TdMnaMxk1SM32kKw1EIJboAn3wC5sgVKWKh7igAesH+zxkDjmdYekVmWz4AuPgA83Cv6bceiO+lsdP3/WUfIaDgBDUD+ybWUaV9NzWOuALTIFKV/V0skR6I5TgAEAjnfByVQpI6YAAAAASUVORK5CYII=");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	border: 1px solid rgba(38, 43, 47, .25);
	content: "";
	cursor: pointer;
	height: 50px;
	left: calc(50% - 25px);
	position: absolute;
	right: inherit;
	top: calc(50% - 35px);
	width: 50px;
	z-index: 101;
}

@media all and (min-width: 768px) {
	.axs-editorial-teaser__item-play-button::before {
		top: calc(50% - 25px);
	}
}

@media all and (min-width: 1024px) {
	.axs-editorial-teaser__item + .axs-editorial-teaser__item {
		margin-top: 0;
	}

	[data-column-number="1"] .axs-editorial-teaser__item-media {
		margin-bottom: 0;
	}

	[data-column-number="1"][data-is-content-alignment-bottom="true"] .axs-editorial-teaser__item-media {
		align-self: flex-start;
	}

	[data-column-number="1"][data-is-content-alignment-bottom="true"] .axs-editorial-teaser__item-text {
		align-self: flex-end;
	}

	[data-column-number="1"] [data-text-position="left"] .axs-editorial-teaser__item-text {
		order: -1;
	}
}

@media all and (min-width: 1440px) {
	[data-column-number="1"] .axs-editorial-teaser__item-text,
	[data-column-number="2"] .axs-editorial-teaser__item-text {
		padding-right: var(--space-xxl);
	}
}
