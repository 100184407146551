.axs-faq {
	display: block;
	margin-bottom: var(--space-xxxl);
}

.axs-faq__search-panel,
.axs-faq__list {
	display: block;
	margin: 0 auto;
}

.axs-faq__search-panel-wrapper {
	background: var(--color-grey-05);
}

.axs-faq__search-panel {
	padding-bottom: var(--space-xxl);
	padding-top: calc(var(--space-xxl) - var(--space-s));
}

.axs-faq__search-form {
	border-bottom: 1px solid var(--color-grey-50);
	position: relative;
}

.axs-faq__search-input {
	background: none;
	border: 0;
	color: var(--color-black);
	padding: var(--space-s) calc(var(--space-m) + var(--icon-size-small)) var(--space-s) 0;
	width: 100%;
}

.axs-faq__search-input::-ms-clear {
	display: none;
}

.axs-faq__search-input::placeholder {
	color: var(--color-grey-70);
	opacity: 1;
}

.axs-faq__reset-search {
	bottom: 0;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
}

.axs-faq__form--searchword-empty .axs-faq__reset-search {
	display: none;
}

.axs-faq__icon-cancel {
	height: var(--icon-size-small);
	width: var(--icon-size-small);
}

.axs-faq__search-icon {
	bottom: 0;
	display: none;
	height: var(--icon-size-small);
	margin: auto;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	width: var(--icon-size-small);
}

.axs-faq__form--searchword-empty .axs-faq__search-icon {
	display: block;
}

.axs-faq__result-count {
	min-height: calc(/* line-height copy-m */ 2.4rem + var(--space-m));
}

.axs-faq__result-count-text {
	padding-top: var(--space-m);
}

.axs-faq__result-count-text--hidden {
	display: none;
}

.axs-faq__text-highlight {
	background: var(--color-grey-15);
}

.axs-faq__group {
	--animation-fade-out: opacity var(--time-m) var(--transition-ease-in) var(--time-xxs);
	--animation-slide-up-parameters: var(--time-l) var(--transition-ease-out) var(--time-xs);
	--animation-slide-up: transform var(--animation-slide-up-parameters), max-height var(--animation-slide-up-parameters);

	max-height: 10000px;
	opacity: 1;
	transform: scaleY(1);
	transform-origin: top;
	transition: var(--animation-fade-out), var(--animation-slide-up);
}

.axs-faq__group-inner {
	margin-top: var(--space-xxl);
}

.axs-faq__group--hidden {
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	transform: scaleY(0);
}

.axs-faq__group-headline {
	font-weight: var(--font-weight-bold);
	margin: 0 auto var(--space-xl);
}

.axs-faq__question {
	border-top: 0 none;
	max-height: 5000px;
	padding: var(--space-m) 0;
	position: relative;
	transform: scaleY(1);
	transform-origin: top;
	transition: max-height var(--time-l) var(--transition-ease-out), opacity var(--time-l) var(--transition-ease-out), transform var(--time-l) var(--transition-ease-out); /* animation for open/close question and hiding/unhiding search results */
}

.axs-faq__question[is-closed="false"] {
	background-color: var(--color-grey-05);
}

.axs-faq__question::after {
	border-bottom: 1px solid var(--color-grey-10);
	bottom: 0;
	content: "";
	left: var(--grid-inner-padding);
	position: absolute;
	right: var(--grid-inner-padding);
}

.axs-faq__question[is-closed="false"]::after {
	border-bottom: 0;
}

.axs-faq__question--hidden {
	max-height: 0;
	opacity: 0;
	padding-bottom: 0;
	padding-top: 0;
	transform: scaleY(0);
}

.axs-faq__question[is-closed="true"] {
	height: auto;
}

.axs-faq__question-header {
	cursor: pointer;
	position: relative;
}

.axs-faq__question-label {
	font-weight: var(--font-weight-bold);
	margin: 0 auto;
	padding-right: calc(var(--space-s) + var(--icon-size-small));
}

.axs-faq__question[is-closed="true"] .axs-faq__question-label {
	font-weight: var(--font-weight-normal);
}

.axs-faq__question-icon-wrapper {
	display: block;
	height: var(--icon-size-small);
	margin-left: 12px;
	position: absolute;
	right: 0;
	top: 0;
	user-select: none;
	width: var(--icon-size-small);
}

.axs-faq__question[is-closed="true"] .axs-faq__question-icon-wrapper {
	transform: rotate(180deg);
}

.axs-faq__question-icon {
	height: var(--icon-size-small);
	transform: rotate(180deg);
	user-select: none;
	width: var(--icon-size-small);
}

.axs-faq__answer {
	max-height: 5000px;
	transform: scaleY(1);
	transform-origin: top;
	transition: transform .1s ease-out;
}

.axs-faq__question[is-closed="true"] .axs-faq__answer {
	max-height: 0;
	overflow: hidden;
	transform: scaleY(0);
}

.axs-faq__answer-content {
	margin: var(--space-s) 0 0;
	padding-right: calc(var(--space-s) + var(--icon-size-small));
}

@media all and (min-width: 768px) {
	.axs-faq__search-input {
		padding: var(--space-s) calc(var(--space-m) + var(--icon-size-large) + var(--space-l)) var(--space-s) var(--space-l);
	}

	.axs-faq__inner-margin {
		padding-left: var(--space-l);
		padding-right: var(--space-l);
	}

	.axs-faq__question-icon-wrapper {
		padding-left: 0;
		right: var(--space-l);
	}

	.axs-faq__form--searchword-empty .axs-faq__search-icon {
		height: var(--icon-size-large);
		right: var(--space-l);
		width: var(--icon-size-large);
	}

	.axs-faq__reset-search {
		right: var(--space-l);
	}

	.axs-faq__question-label {
		padding-right: calc(var(--space-s) + var(--icon-size-small));
	}
}

@media all and (min-width: 1024px) {
	.axs-faq__question::after {
		left: 0;
		right: 0;
	}
}
