/*******************************************************************

    .axs-ondemand-configurator__modelfinder
    mobile-first

*******************************************************************/

/* module container */

.axs-ondemand-configurator__modelfinder {
	display: block;
}

.axs-ondemand-configurator__modelfinder-body {
	background: var(--color-grey-05);
	padding-bottom: var(--space-l);
	padding-top: var(--space-xxxl);
}

/* module intro */

.axs-ondemand-configurator__modelfinder-intro {
	margin-bottom: var(--space-m);
}

.axs-ondemand-configurator__modelfinder-intro *:last-child {
	margin-bottom: 0;
}

.axs-ondemand-configurator__modelfinder-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
}

.axs-ondemand-configurator__modelfinder-copy {
	margin-bottom: var(--space-m);
}

/* model list */

.axs-ondemand-configurator__modelfinder-modellist {
	display: flex;
	flex-direction: column;
	position: relative;
}

.axs-ondemand-configurator__modelfinder-noresults {
	background-color: var(--color-grey-10);
	display: none;
	padding: var(--space-l) var(--space-m);
}

.axs-ondemand-configurator--no-results .axs-ondemand-configurator__modelfinder-noresults {
	display: block;
}

.axs-ondemand-configurator__modelfinder-modellist-item {
	max-width: 100%;
	min-height: 1px; /* IE11 Flexbox fix */
	padding: calc(var(--space-xs) / 2) 0;
}

@media all and (min-width: 855px) {
	.axs-ondemand-configurator__modelfinder-modellist {
		flex-flow: row wrap;
		left: -1.5px;
	}

	.axs-ondemand-configurator__modelfinder-modellist-item {
		display: flex;
		padding: calc(var(--space-xs) / 2);
		width: 50%;
	}
}

@media all and (min-width: 1275px) {
	.axs-ondemand-configurator__modelfinder-modellist-item {
		width: 33.33%;
	}
}

/* model tile */

.axs-ondemand-configurator__modelfinder-modeltile {
	background: var(--color-white);
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: var(--space-m);
	width: 100%;
}

.axs-ondemand-configurator__modelfinder-modeltile-hidden {
	display: none;
}

.axs-ondemand-configurator__modelfinder-modeltile-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.axs-ondemand-configurator__modelfinder-modeltile-header-left {
	flex: 1 0 55%;
}

.axs-ondemand-configurator__modelfinder-modeltile-header-spacer {
	flex: 0 0 8%;
}

.axs-ondemand-configurator__modelfinder-modeltile-header-right {
	flex: 0 0 37%;
}

.axs-ondemand-configurator__modelfinder-modeltile-headline,
.axs-ondemand-configurator__modelfinder-modeltile-motor,
.axs-ondemand-configurator__modelfinder-modeltile-price-unit,
.axs-ondemand-configurator__modelfinder-modeltile-price-value {
	display: block;
	margin-bottom: var(--space-xs);
}

.axs-ondemand-configurator__modelfinder-modeltile-headline,
.axs-ondemand-configurator__modelfinder-modeltile-price-value {
	font-weight: var(--font-weight-bold);
}

.axs-ondemand-configurator__modelfinder-modeltile-price {
	text-align: right;
}

.axs-ondemand-configurator__modelfinder-modeltile-price-values {
	display: none;
}

.axs-ondemand-configurator__modelfinder-modeltile-price-selected {
	display: inherit;
}

/* model tile image */

.axs-ondemand-configurator__modelfinder-modeltile-images {
	min-height: 1px; /* IE 11 flexbox fix */
	text-align: center;
}

.axs-ondemand-configurator__modelfinder-modeltile-image {
	display: block;
	height: auto;
	margin: auto;
	max-width: 100%;
	transform: scale(.95);
	transition: transform .3s;
}

.axs-ondemand-configurator__modelfinder-modellist-item:hover .axs-ondemand-configurator__modelfinder-modeltile-image {
	transform: scale(1);
}

.axs-ondemand-configurator__modelfinder-modeltile-image-selected {
	display: none;
}

.axs-ondemand-configurator__modelfinder-modellist-item:hover .axs-ondemand-configurator__modelfinder-modeltile-image-selected,
.axs-ondemand-configurator__modelfinder-modeltile-selected .axs-ondemand-configurator__modelfinder-modeltile-image-selected {
	display: block;
}

.axs-ondemand-configurator__modelfinder-modellist-item:hover .axs-ondemand-configurator__modelfinder-modeltile-image-base,
.axs-ondemand-configurator__modelfinder-modeltile-selected .axs-ondemand-configurator__modelfinder-modeltile-image-base {
	display: none;
}
