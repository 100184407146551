.axs-icon-text-highlight {
	display: block;
	padding-bottom: calc(var(--space-xxxl) - var(--space-xxl));
	padding-top: var(--space-xxl);
}

.axs-icon-text-highlight--background-grey {
	background-color: var(--color-grey-05);
}

.axs-icon-text-highlight--background-black {
	background-color: var(--color-black);
	color: var(--color-white);
	fill: var(--color-white);
}

.axs-icon-text-highlight__headline,
.axs-icon-text-highlight__item-icon,
.axs-icon-text-highlight__item-headline,
.axs-icon-text-highlight__item-copy {
	color: inherit;
	fill: inherit;
}

.axs-icon-text-highlight__headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
	text-align: center;
}

.axs-icon-text-highlight__item {
	margin-bottom: var(--space-xxl);
	text-align: center;
}

.axs-icon-text-highlight__item-icon,
.axs-icon-text-highlight__item-headline,
.axs-icon-text-highlight__item-copy {
	margin-bottom: var(--space-s);
}

.axs-icon-text-highlight__item-headline:last-child,
.axs-icon-text-highlight__item-copy:last-child {
	margin-bottom: 0;
}

.axs-icon-text-highlight__item-icon {
	fill: inherit;
	height: var(--icon-size-large);
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	transition: transform var(--time-xxl) var(--transition-ease-out) 84ms, opacity var(--time-xxl) var(--transition-ease-out) 126ms;
	width: var(--icon-size-large);
}

.axs-icon-text-highlight__item-text {
	transition: transform var(--time-xxl) var(--transition-ease-out), opacity var(--time-xxl) var(--transition-ease-out) 42ms;
}

.axs-icon-text-highlight__item-headline {
	font-weight: var(--font-weight-bold);
}

.axs-icon-text-highlight__item-copy i {
	font-style: italic;
}

.axs-icon-text-highlight__item-link-wrapper {
	margin-top: var(--space-xxs);
}

.axs-icon-text-highlight__item-link-wrapper:first-child {
	margin-top: 0;
}

/* hidden styles */

.axs-icon-text-highlight__item[data-scroll-reveal="hidden"] .axs-icon-text-highlight__item-text {
	opacity: 0;
	transform: translateY(160px);
}

.axs-icon-text-highlight__item[data-scroll-reveal="hidden"] .axs-icon-text-highlight__item-icon {
	opacity: 0;
	transform: translateY(36px);
}
