.axs-icon-text-condition {
	display: block;
	padding-bottom: calc(var(--space-xxxl) - var(--space-xl));
	padding-top: var(--space-xxl);
}

.axs-icon-text-condition--background-grey {
	background-color: var(--color-grey-05);
}

.axs-icon-text-condition--background-black {
	background-color: var(--color-black);
	color: var(--color-white);
	fill: var(--color-white);
}

.axs-icon-text-condition__headline,
.axs-icon-text-condition__item-headline,
.axs-icon-text-condition__item-copy {
	color: inherit;
}

.axs-icon-text-condition__headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
	text-align: center;
}

.axs-icon-text-condition__item {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: var(--space-xl);
}

.axs-icon-text-condition__item-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xs);
}

.axs-icon-text-condition__item-copy {
	margin-bottom: var(--space-s);
}

.axs-icon-text-condition__item-headline:last-child,
.axs-icon-text-condition__item-copy:last-child {
	margin-bottom: 0;
}

.axs-icon-text-condition__item-copy i {
	font-style: italic;
}

.axs-icon-text-condition__item-icon {
	color: inherit;
	fill: inherit;
	height: var(--icon-size-small);
	margin-right: var(--space-m);
	overflow: hidden;
	transition: transform 750ms var(--transition-ease-out), opacity 750ms var(--transition-ease-out) 42ms;
	width: var(--icon-size-small);
}

.axs-icon-text-condition__item-text {
	transition: transform 750ms var(--transition-ease-out), opacity 750ms var(--transition-ease-out) 42ms;
	width: calc(100% - 42px);
}

.axs-icon-text-condition__item-link-entry {
	display: block;
	margin-top: var(--space-xxs);
}

.axs-icon-text-condition__item-link-entry:first-child {
	margin-top: 0;
}

.axs-icon-text-condition__item[data-scroll-reveal="hidden"] .axs-icon-text-condition__item-text,
.axs-icon-text-condition__item[data-scroll-reveal="hidden"] .axs-icon-text-condition__item-icon {
	opacity: 0;
	transform: translateY(160px);
}
