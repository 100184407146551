/*******************************************************************
    hub-search
    mobile-first
    selector/scope: .axs-module-hub-search
*******************************************************************/

.axs-module-hub-search {
	display: flex;
	height: 80vh;
	position: relative;
}

.axs-module-hub-search[data-full-screen="active"] {
	background-color: var(--color-white);
	height: 100vh;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 11;
}

.axs-module-hub-search-with-margin {
	margin-bottom: var(--space-xxxl);
}

.axs-module-hub-search-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
	margin-top: 0;
	padding-top: var(--space-m);
	width: 100%;
}

/* utils, general */

.axs-module-hub-search-only-desktop {
	display: none;
}

/* fix and scrollable contents */

.axs-module-hub-search-sticky-content {
	box-shadow: 0 2px 4px 0 var(--color-grey-10);
	flex: 0 1 auto;
	position: relative;
	z-index: 2;
}

.axs-hub-search-detail-sticky-content-desktop {
	box-shadow: none;
}

.axs-module-hub-search-scrollable-content {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	overflow: auto;
}

/* template */

.axs-module-hub-search-header {
	background: var(--color-white);
	padding: 0 24px;
}

/* map */

.axs-module-hub-search-map-detail,
.axs-module-hub-search-map-main {
	background: var(--color-grey-20);
	width: 100%;
}

.axs-module-hub-search-map-main {
	bottom: 0;
	left: 0;
	position: absolute !important; /* needed to overwrite the inline styles from google api */
	right: 0;
	top: 0;
}

/* map controls */

.axs-module-hub-search-map-wrapper {
	overflow: hidden;
	position: relative;
}

.axs-module-hub-search-map-controls {
	bottom: 12px;
	position: absolute;
	right: 12px;
	z-index: 1;
}

.axs-module-hub-search-map-control {
	background-color: var(--color-white);
	border: none;
	box-shadow: 0 2px 4px 0 var(--color-grey-10);
	display: block;
	height: 36px;
	margin: 0 0 2px;
	padding: 0;
	position: relative;
	width: 36px;
}

.axs-module-hub-search-zoom-icon {
	background-position: center center;
	background-repeat: no-repeat;
	color: transparent;
	display: block;
	font: 0/0 serif;
	height: 36px;
	left: 0;
	overflow: hidden;
	position: absolute;
	text-shadow: none;
	top: 0;
	width: 36px;
}

.axs-module-hub-search-zoom-in .audi-icon-zoom-plus-small,
.axs-module-hub-search-zoom-out .audi-icon-zoom-minus-small {
	height: var(--icon-size-small);
	margin: 6px;
	stroke: var(--color-black);
	width: var(--icon-size-small);
}

.axs-module-hub-search-zoom-in:hover .audi-icon-zoom-plus-small,
.axs-module-hub-search-zoom-out:hover .audi-icon-zoom-minus-small {
	stroke: #4d4d4d;
}

.axs-module-hub-search-map-control:active,
.axs-module-hub-search-map-control:focus {
	border: none;
	outline: none;
}

/* off-canvas */

.axs-module-hub-search .axs-module-hub-search-panel {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	overflow-x: hidden;
	width: 100%;
}

.axs-module-hub-search .axs-module-hub-search-panel-first-mobile {
	/* safari fix, otherwise map height is 0 */
	height: auto;
}

.axs-module-hub-search-off-canvas-wrapper-mobile,
.axs-module-hub-search-off-canvas-wrapper-desktop {
	height: 100%;
}

.axs-module-hub-search-off-canvas-wrapper-mobile {
	display: flex;
	flex: 1;
}

.axs-module-hub-search-off-canvas-mobile {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.axs-module-hub-search-toggle {
	background: none;
	border: none;
	border-spacing: 0;
	color: var(--color-black);
	font-size: 1.8rem;
	font-weight: var(--font-weight-normal);
	line-height: 2rem;
	margin: 0;
	padding: 18px 24px;
	position: relative;
	text-align: left;
	text-decoration: none;
	width: 100%;
}

.axs-module-hub-search-toggle-first-content {
	border-top: 1px solid var(--color-grey-05);
}

.axs-module-hub-search-toggle-text {
	margin-right: var(--space-s);
	position: relative;
}

.axs-module-hub-search-results-text {
	display: block;
}

.axs-module-hub-search-toggle-icon {
	bottom: 0;
	height: 1.6em;
	left: auto;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 1.6em;
}

.axs-module-hub-search-toggle-icon-backward {
	left: 12px;
	right: auto;
	transform: rotate(180deg);
}

.axs-module-hub-search-back-link {
	padding-left: 42px;
}

.axs-module-hub-search-results-counter {
	color: var(--color-grey-50);
	margin: 12px 0;
}

/* prevent flickering when canvas is initiated */

.axs-module-hub-search-off-canvas-desktop {
	display: none;
}

.off-canvas.is-active-off-canvas-componente .axs-module-hub-search-off-canvas-desktop {
	display: block;
}

.off-canvas.is-active-off-canvas-componente .axs-module-hub-search-first-desktop-panel {
	display: flex;
}

/* hub search result */

.axs-module-hub-search-results {
	background: var(--color-grey-05);
	flex: 1;
	padding: 12px;
}

.axs-module-hub-search-hub {
	background: var(--color-white);
	margin-bottom: 6px;
	padding: 12px 12px 18px 42px;
	position: relative;
}

.axs-module-hub-search-hub-is-private {
	fill: var(--color-red);
}

.axs-module-hub-search-hub-is-public {
	fill: var(--color-black);
}

.axs-module-hub-search-hub-address {
	color: var(--color-grey-50);
}

.axs-module-hub-search-result-icon {
	fill: inherit;
	height: var(--icon-size-small);
	left: 12px;
	position: absolute;
	top: 15px;
	width: var(--icon-size-small);
}

/* detail view */

.axs-module-hub-search-map-detail {
	background: var(--color-grey-05);
	height: 200px;
	max-height: 25vh;
	min-height: 200px;
}

.axs-module-hub-search-map-detail-inactive {
	display: none;
}

.axs-module-hub-search-detail {
	display: flex;
	flex-direction: column;
	height: 100%; /* needed for firefox */
}

.axs-module-hub-search-flexbox-wrapper {
	flex: 1 1 auto;
	overflow: auto;
}

.axs-module-hub-search-detail-head {
	box-shadow: 0 2px 4px 0 var(--color-grey-10);
	flex: 0 1 auto;
	z-index: 3;
}

.axs-module-hub-search-detail-hub-name {
	display: block;
	font-weight: var(--font-weight-bold);
	margin: 12px 19px 0;
}

.axs-module-hub-search-detail-content-wrapper {
	padding: var(--space-m) var(--space-s);
}

.axs-module-hub-search__opening-times-wrapper {
	margin-bottom: 18px;
}

.axs-module-hub-search__opening-times-wrapper:empty {
	margin: 0;
	padding: 0;
}

.axs-module-hub-search__opening-times {
	background-color: var(--color-grey-05);
	display: block;
	padding: var(--space-s) var(--space-s) 0;
	position: relative;
}

.axs-module-hub-search-opening-times-toggle-content {
	max-height: 1000px;
	overflow: hidden;
	transition: max-height var(--time-l) var(--transition-ease-in);
}

[is-open="false"].axs-module-hub-search__opening-times .axs-module-hub-search-opening-times-toggle-content {
	max-height: 0;
	transition: max-height var(--time-l) var(--transition-ease-out);
}

.axs-module-hub-search__opening-times-day {
	display: flex;
	padding-bottom: var(--space-s);
}

.axs-module-hub-search__opening-times-toggle {
	font-weight: var(--font-weight-bold);
}

.axs-module-hub-search__opening-times-toggle:hover {
	cursor: pointer;
}

.axs-module-hub-search__opening-times-day-name {
	flex-grow: 1;
}

.axs-module-hub-search__opening-times-day-times {
	margin-right: calc(24px + var(--space-xs));
	min-width: 85px;
}

.axs-module-hub-search__opening-times-toggle-icon-wrapper {
	height: var(--icon-size-small);
	position: absolute;
	right: var(--space-s);
	top: 8px;
	width: var(--icon-size-small);
}

.axs-module-hub-search__opening-times-toggle-icon {
	height: var(--icon-size-small);
	transition: transform var(--time-l);
	width: var(--icon-size-small);
}

[is-open="true"].axs-module-hub-search__opening-times .axs-module-hub-search__opening-times-toggle-icon {
	transform: rotate(180deg);
}

.axs-module-hub-search__opening-times-notes {
	color: var(--color-grey-50);
	margin-top: var(--space-xs);
}

.axs-module-hub-search-detail-links {
	padding: 12px 12px 6px;
}

.axs-module-hub-search-detail-link-wrapper {
	display: block;
	list-style: none;
	margin-bottom: 9px;
	padding: 0;
}

.axs-module-hub-search-detail-link-wrapper:last-child {
	margin-bottom: 0;
}

.axs-module-hub-search-detail-link-choose {
	justify-content: center;
	max-width: 100%;
	width: 100%;
}

.axs-module-hub-search-detail-link-small.axs-module-hub-search-detail-link-small {
	font-size: 1.2rem;
}

.axs-module-hub-search-detail-item {
	margin-bottom: var(--space-m);
	min-height: var(--icon-size-small);
	padding-left: calc(var(--icon-size-small) + 18px); /* icon width + regular padding */
	position: relative;
}

.axs-module-hub-search-detail-item-has-link {
	padding-left: 0;
}

.axs-module-hub-search-detail-item-has-link .axs-module-hub-search-detail-icon {
	pointer-events: all;
}

.axs-module-hub-search-detail-item-link {
	display: block;
	margin-left: calc(var(--icon-size-small) + 18px); /* icon width + regular padding */
	min-height: var(--icon-size-small);
}

.axs-module-hub-search-detail-icon {
	height: var(--icon-size-small);
	left: 0;
	position: absolute;
	top: 0;
	width: var(--icon-size-small);
}

.axs-module-hub-search-detail-icon-phone {
	fill: var(--color-white);
	stroke: var(--color-black);
}

/* filter */

.axs-module-hub-search-filterform {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: var(--space-s);
	min-height: 26px; /* checkbox-height **/
}

.axs-module-hub-search-filterform-hidden {
	display: none;
}

.axs-module-hub-search-filter-wrapper {
	display: flex;
	flex-flow: row wrap;
	padding-bottom: calc(var(--space-s) - var(--space-xs)); /* space-xs under each filter checkox */
}

.axs-module-hub-search-filter {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	justify-content: center;
	margin-bottom: var(--space-xs);
	min-height: 26px;
	padding-right: var(--space-m);
}

.axs-module-hub-search-filter .audi-checkbox-symbol {
	bottom: 0;
	box-sizing: content-box;
	margin: auto;
	top: 0;
}

@media all and (min-width: 1024px) {
	.axs-module-hub-search-headline {
		margin-bottom: var(--space-xxl);
	}

	/* utils, common */

	.axs-module-hub-search-only-mobile {
		display: none;
	}

	.axs-module-hub-search-only-desktop {
		display: block;
	}

	/* fix and scrollable contents */

	.axs-hub-search-detail-sticky-content-desktop {
		box-shadow: 0 2px 4px 0 var(--color-grey-10);
	}

	/* hub search result */

	.axs-module-hub-search-hub.hover::before,
	.axs-module-hub-search-hub:hover::before {
		background: var(--color-black);
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 5px;
	}

	/* detail view */

	.axs-module-hub-search-flexbox-wrapper {
		display: flex;
		flex-direction: column;
	}

	.axs-module-hub-search-map-detail,
	.axs-module-hub-search-detail-image-wrapper {
		position: relative;
	}

	.axs-module-hub-search-detail-hub-name {
		color: var(--color-white);
		left: 0;
		margin: -68% 0 0;
		padding: 24px 60px 24px 24px;
		top: 0;
		width: 100%;
	}

	.axs-module-hub-search-detail-image-wrapper {
		background-size: cover;
		display: block;
		padding-top: 68%;
	}

	.axs-module-hub-search-detail-close {
		color: var(--color-white);
		height: var(--icon-size-large);
		padding: 0;
		position: absolute;
		right: var(--space-xs);
		top: var(--space-s);
		width: var(--icon-size-large);
	}

	.axs-module-hub-search-detail-close:hover,
	.axs-module-hub-search-detail-close:focus {
		color: var(--color-grey-70);
	}

	.axs-module-hub-search-detail-close-icon {
		height: var(--icon-size-large);
		width: var(--icon-size-large);
	}

	.axs-module-hub-search-detail-content-wrapper {
		padding: var(--space-m) var(--space-l);
	}

	.axs-module-hub-search-detail-links {
		padding: var(--space-m) var(--space-l);
	}

	/* no image for hub detail view */
	.axs-module-hub-search-detail-image-wrapper-no-image {
		padding-top: 0;
	}

	.axs-module-hub-search-detail-image-wrapper-no-image .axs-module-hub-search-detail-hub-name {
		color: var(--color-black);
		margin: 0;
	}

	.axs-module-hub-search-detail-image-wrapper-no-image .axs-module-hub-search-detail-close {
		color: var(--color-black);
	}

	.axs-module-hub-search-detail-image-wrapper-no-image .axs-module-hub-search-detail-close:hover,
	.axs-module-hub-search-detail-image-wrapper-no-image .axs-module-hub-search-detail-close:focus {
		color: var(--color-grey-70);
	}

	/* filter */

	.axs-module-hub-search-filter-wrapper {
		padding-bottom: calc(var(--space-m) - var(--space-xs)); /* space-xs under each filter checkox */
	}

	.axs-module-hub-search-filter-label {
		font-size: 1.8rem;
		line-height: 3rem;
	}

	.axs-module-hub-search-filterform {
		margin-top: 0;
	}

	.axs-module-hub-search-filterform-first-content {
		display: none;
	}
	/* inner off canvas (desktop view) */

	.axs-module-hub-search-map-main {
		left: 36vw;
		width: 64vw;
	}

	.axs-module-hub-search-off-canvas-desktop {
		display: block;
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		width: 36vw;
	}

	.axs-module-hub-search-off-canvas-wrapper {
		display: flex;
		flex-direction: row;
		height: 100%;
	}

	.axs-module-hub-search-off-canvas-wrapper-desktop.off-canvas.is-active-off-canvas-componente {
		display: flex;
	}

	.axs-module-hub-search-first-desktop-panel {
		flex: 1;
		width: 100%;
	}

	.off-canvas.is-active-off-canvas-componente .axs-module-hub-search-first-desktop-panel {
		flex-direction: column;
	}
}

@media all and (min-width: 1041.666px) {
	/* width where sidebar is exactly 375px (36% of 1041.66px are 375px) */

	.axs-module-hub-search-map-main {
		left: 375px;
		width: calc(100vw - 375px);
	}

	.axs-module-hub-search-off-canvas-desktop {
		width: 375px;
	}
}

.axs-module-hub-search-marker-mouseover,
.axs-module-hub-search-marker-mouseout {
	/* Google maps adds inline styles to the markers, therefore
	 * we need to use !important to overwrite the inline styles */
	opacity: 1 !important;
	overflow: visible !important;
}

.axs-module-hub-search-map-main div[title^="Unique"] {
	opacity: 1 !important; /* Hack for IE11 */
}

.axs-module-hub-search-map-main div[title^="Unique"] img {
	height: auto !important; /* Hack for IE11 */
	width: auto !important; /* Hack for IE11 */
}

.axs-module-hub-search-marker-mouseover > img {
	transform: scale(2);
	transform-origin: bottom center;
	transition: transform var(--time-m) var(--transition-ease-in-out);
}

.axs-module-hub-search-marker-mouseout > img {
	transform: scale(1);
	transform-origin: bottom center;
	transition: transform var(--time-m) var(--transition-ease-in);
}
