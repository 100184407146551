/*******************************************************************
    module: country-selector
    mobile-first
*******************************************************************/

.axs-module-country-selector {
	display: block;
	width: 100%;
}

.axs-module-country-selector .country-selector-map-and-overlay-wrapper {
	display: none;
}

.axs-module-country-selector .country-selector-map {
	bottom: 0;
	left: 0;
	margin: auto;
	pointer-events: initial;
	position: absolute;
	right: 0;
	top: 0;
}

.axs-module-country-selector .country-selector-map,
.axs-module-country-selector .country-selector-map * {
	transition: all 250ms linear 100ms;
}

.country-selector-map[data-zoom-level="world"] .country-selector-map-continent[data-state="active"],
.country-selector-map[data-zoom-level="continent"] .country-selector-map-country[data-state=active] {
	cursor: pointer;
}

.country-selector-map .country-selector-map-country {
	fill: var(--color-grey-20);
}

.country-selector-map[data-zoom-level="world"] .country-selector-map-continent[data-state="active"] .country-selector-map-country {
	fill: var(--color-grey-30);
}

.country-selector-map[data-zoom-level="world"] .country-selector-map-continent[data-state="active"]:hover .country-selector-map-country {
	fill: var(--color-grey-60);
}

.country-selector-map[data-zoom-level="world"] .country-selector-map-continent[data-state="active"][data-selection="selected"] .country-selector-map-country {
	fill: var(--color-black);
}

.country-selector-map[data-zoom-level="continent"] .country-selector-map-continent[data-state="active"] .country-selector-map-country[data-state="active"],
.country-selector-map[data-zoom-level="continent"] .country-selector-map-continent[data-state="active"][data-selection="selected"] .country-selector-map-country[data-state="active"] {
	fill: var(--color-grey-30);
}

.country-selector-map[data-zoom-level="continent"] .country-selector-map-continent[data-state="active"]:not([data-selection="selected"]) .country-selector-map-country[data-state="active"] {
	fill: var(--color-grey-20);
}

.country-selector-map[data-zoom-level="continent"] .country-selector-map-continent[data-state="active"] .country-selector-map-country[data-state="active"]:hover {
	fill: var(--color-grey-60);
}

.country-selector-map[data-zoom-level="continent"] .country-selector-map-continent[data-state="active"]:not([data-selection="selected"]) .country-selector-map-country[data-state="active"]:hover {
	fill: var(--color-grey-20);
}

.country-selector-map[data-zoom-level="continent"] .country-selector-map-continent[data-state="active"] .country-selector-map-country[data-state="active"][data-selection="selected"] {
	fill: var(--color-black);
}

.country-selector-map[data-zoom-level="continent"] .country-selector-map-continent[data-state="active"]:not([data-selection="selected"]) .country-selector-map-country[data-state="active"][data-selection="selected"] {
	fill: var(--color-grey-20);
}

.axs-module-country-selector .country-selector-map-container {
	background-color: #fcfcfc;
	min-height: 100%;
	padding-bottom: calc(1001 * 100% / 2000); /* aspect ratio of svg viewbox */
	position: relative;
}

.axs-module-country-selector .country-selector-dropdowns-container {
	padding-left: 4%;
	padding-right: 4%;
	padding-top: var(--space-xl);
	width: 100%;
}

.axs-module-country-selector-cta-standalone {
	display: none;
	max-width: 100%;
	width: 100%;
}

.axs-module-country-selector-cta-standalone-wrapper[data-state="inactive"] .axs-module-country-selector-cta-standalone:first-child,
.axs-module-country-selector-cta-standalone[data-visibility="visible"] {
	display: inline-flex;
}

.axs-module-country-selector .country-selector-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
}

.axs-module-country-selector .axs-country-selector-dropdown,
.axs-module-country-selector .axs-continent-selector-dropdown,
.axs-module-country-selector .country-selector-subline {
	margin-bottom: var(--space-xxl);
}

@media all and (min-width: 768px) {
	.axs-module-country-selector .country-selector-map-and-overlay-wrapper {
		display: block;
		min-height: 300px;
		overflow: hidden;
		position: relative;
		transition: all 300ms ease-in 400ms;
	}

	.axs-module-country-selector .country-selector-overlay-container {
		height: 100%;
		left: 100%;
		position: absolute;
		top: 0;
		transition: all 500ms ease-in 600ms;
		transition-delay: 200ms;
		width: 100%;
	}

	.axs-module-country-selector .country-selector-modal-close-button {
		background-color: var(--color-black);
		cursor: pointer;
		height: var(--icon-size-large);
		position: absolute;
		right: var(--space-s);
		top: var(--space-s);
		width: var(--icon-size-large);
	}

	.axs-module-country-selector .country-selector-modal-close-button .country-selector-close-icon {
		fill: var(--color-white);
		height: var(--icon-size-large);
		width: var(--icon-size-large);
	}

	.axs-module-country-selector .country-selector-overlay-container[data-visibility="visible"] {
		left: 0;
		transition: all 500ms ease-in 600ms;
	}

	.axs-module-country-selector .country-selector-overlay-single-country {
		background-color: var(--color-black);
		display: none;
		height: 100%;
		width: 100%;
	}

	.axs-module-country-selector .country-selector-overlay-single-country[data-visibility="visible"] {
		display: block;
	}

	.axs-module-country-selector .country-selector-overlay-image {
		height: auto;
		width: 100%;
	}

	.axs-module-country-selector .country-selector-overlay-content-wrapper {
		padding-left: var(--space-xl);
		padding-right: var(--space-xl);
		padding-top: var(--space-l);
	}

	.axs-module-country-selector .country-selector-modal-headline {
		font-weight: var(--font-weight-bold);
		margin-bottom: var(--space-s);
	}

	.axs-module-country-selector .country-selector-modal-copy {
		margin-bottom: var(--space-l);
	}

	.country-selector-overlay-single-country[data-theme="dark"] .country-selector-modal-headline,
	.country-selector-overlay-single-country[data-theme="dark"] .country-selector-modal-copy {
		color: var(--color-white);
	}

	.axs-module-country-selector .axs-module-country-selector-modal-cta {
		margin-bottom: calc(var(--space-xs) + var(--space-xxl));
	}

	.axs-module-country-selector .country-selector-dropdowns-container {
		padding-left: var(--space-xl);
		padding-right: var(--space-xl);
		width: calc((100% - var(--space-xl)) / 2); /* half of the available width minus the possible XL margin between two inline input fields */
	}

	.axs-module-country-selector .country-selector-map-zoomout-button {
		background-color: var(--color-black);
		cursor: pointer;
		height: var(--icon-size-large);
		position: absolute;
		right: var(--space-s);
		top: var(--space-s);
		width: var(--icon-size-large);
	}

	.axs-module-country-selector .audi-icon-world-view {
		height: var(--icon-size-large);
		width: var(--icon-size-large);
	}

	.axs-module-country-selector .country-selector-map-zoomout-button[data-visibility="hidden"] {
		display: none;
	}

	.axs-module-country-selector-cta-standalone-wrapper[data-state="inactive"] .axs-module-country-selector-cta-standalone:first-child,
	.axs-module-country-selector-cta-standalone[data-visibility="visible"] {
		display: none;
	}
}

@media all and (min-width: 1280px) {
	.axs-module-country-selector {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	.axs-module-country-selector .country-selector-map-and-overlay-wrapper {
		width: 64%;
	}

	.axs-module-country-selector .country-selector-overlay-content-wrapper {
		padding-left: calc(var(--space-xs) + var(--space-xxl));
		padding-right: calc(var(--space-xs) + var(--space-xxl));
	}

	.axs-module-country-selector .country-selector-dropdowns-container {
		padding-left: calc(var(--space-xs) + var(--space-xxl));
		padding-right: calc(var(--space-xs) + var(--space-xxl));
		padding-top: calc(var(--space-xl) + var(--space-xxl));
		width: 36%;
	}
}
