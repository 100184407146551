.audi-axs-caroussel {
	display: block;
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	width: 100%;
}

.audi-axs-caroussel-list {
	font-size: 0px;
	line-height: 0px;
	transform: translateX(100%);
	transition: all .8s cubic-bezier(.77, 0, .175, 1);
}

[is-transition-enabled="false"] .audi-axs-caroussel-list {
	transition: none !important;
}

.audi-axs-caroussel-item {
	display: inline-block;
	width: 100%;
}

.audi-axs-caroussel-item-image {
	width: 100%;
}

.audi-axs-caroussel-arrow-navigation-icon {
	cursor: pointer;
	display: none;
	height: var(--icon-size-large);
	left: 0;
	position: absolute;
	top: calc(50% - var(--icon-size-large) / 2);
	z-index: 2;
}

.audi-axs-caroussel-arrow-navigation-forward-icon {
	left: auto;
	right: 0;
}

.audi-axs-caroussel-arrow-navigation-forward-icon .audi-back-large {
	transform: rotate(180deg);
}

.audi-axs-caroussel-arrow-navigation-icon .audi-back-large {
	height: var(--icon-size-large);
	width: var(--icon-size-large);
}

.audi-axs-caroussel-pagination-list {
	bottom: 24px;
	font-size: 0;
	left: 0;
	line-height: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	text-align: center;
	z-index: 2;
}

.audi-axs-caroussel-pagination-item {
	background-color: var(--color-black);
	cursor: pointer;
	display: inline-block;
	height: 8px;
	margin: 0 2px;
	opacity: .2;
	text-indent: -9999px;
	width: 20px;
}

[name="audi-axs-caroussel-pagination"] {
	display: none;
}
