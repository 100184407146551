.axs-md-tooltip {
	cursor: pointer;
	display: inline-block;
	height: 20px;
	margin-bottom: -6px;
	margin-left: 3px;
	width: 20px;
}

.axs-md-tooltip::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RENGNDZCMjkyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RENGNDZCMkEyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0Y0NkIyNzI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0Y0NkIyODI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvP4m7EAAADLSURBVHjaYmDADjiBOAGI1wHxbSD+D8U3gHg1EEcBMRsDkSAWiB8iGYIL3wPiEHwGMQPxZCIMQsddUL0YYBoZhiEbigKiKDAMhuHeBwXuEyoYeBsWUQRdBwNEGBrFAiTCCUU7IyMjsSnEm4HIJEIsvgey+ie+RArxKdEu/cVERe/CDXzBQD3wFGTgBSoaeBZk4EoqGrgVVrJQNWFTK+uF07RwoKT4asdVfMFAOJG5BxRmgcTGFhu0OAIV9+eRDDkDxEuhBmF1FUCAAQCfWtTvGIyPEwAAAABJRU5ErkJggg==");
	background-position: center center;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	height: 20px;
	left: 0;
	margin-bottom: -6px;
	speak: none;
	top: 0;
	width: 20px;
}

.axs-md-tooltip:hover::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RENGNDZCMkQyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RENGNDZCMkUyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0Y0NkIyQjI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0Y0NkIyQzI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pgd7iYkAAAEiSURBVHjaYnT2CWbAAjiBOByI/YBYF4hVoOI3gfgyEK8H4jVA/AtdIxMWw2KB+AYQzwfiQCTDQEAdiEOAeClUTQg+A5mBeDIQLwJiOQbCQBGIVwNxF1QvGLAgKQAZlslAOiiF0mXILowi0zBkQ0NgBrJBnU0paAeZxQQ1WRqfyj2b14AxAQCKvBAWaPLAC1x8Q4h1pTfIhQYM1AOWIBdKEPIuCS6VZqKid0HgF8jAF1T08lOQgReoaOBZkIErqWjgVkZgaQMqWW4TSotEgDtArA1y4XdYPqQQ1MAiBQSWAfF0CgzrhgUdcrLJBeIpZBjWAcSV2MrDv1BDI4D4EZFhFgQ17C+28hAGVkKLeD9oPldByp5nodUAKPtsQjYIBgACDAD6UTikUcwgiAAAAABJRU5ErkJggg==");
}

.axs-md-tooltip .axs-md-tooltip-content {
	background-color: var(--color-black);
	color: var(--color-white);
	display: none;
	left: auto;
	max-width: 280px;
	padding: 15px;
	position: absolute;
	right: 0;
	top: 30px;
	width: 400px;
	z-index: 2;
}

.axs-md-tooltip:hover .axs-md-tooltip-content {
	display: block;
}

@media all and (min-width: 769px) {
	.axs-md-tooltip {
		position: relative;
	}

	.axs-md-tooltip .axs-md-tooltip-content {
		bottom: 30px;
		left: 0;
		max-width: none;
		right: auto;
		top: auto;
	}
}
