/* =========================================================================
Module MAP
========================================================================= */

#map {
	min-height: 370px;
}

.axs-map .axs-el-hdl {
	margin: 0 0 9px;
}

.axs-map .axs-el-pg {
	margin: 0 0 19px;
}

/* Tooltip
========================================================================== */

.map-tooltip {
	background: var(--color-black);
	bottom: 55px;
	color: var(--color-white);
	display: none;
	font-size: 10px;
	left: 0;
	padding: 5px 10px;
	position: absolute;
	white-space: nowrap;
}

.map-main {
	clear: both;
	display: block;
	position: relative;
	z-index: 1;
}

.map-map {
	height: 370px;
	width: 100%;
}

/*  MAP Partner Detail
============================================================================= */

.map-details-active .map-partner-details {
	height: auto;
	min-height: 200px;
	min-width: 200px;
	opacity: 1;
	width: auto;
}

.map-detail-main {
	padding: 20px 0;
}

.map-detail-main p {
	margin: 0;
}

.map-detail-main p.axs-el-pg {
	margin-bottom: 20px;
}

.map-detail-header img {
	display: none;
	width: 100%;
}

.map-detail-header .axs-button-close {
	display: none;
	right: 0;
	top: 0;
}

.map-controls {
	position: absolute;
	right: 20px;
	top: 20px;
}

.map-controls > button {
	background: none;
	border: none;
	display: block;
	height: 23px;
	margin: 0 0 20px;
	padding: 0;
	position: relative;
	width: 23px;
}

.map-controls > button > span {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 23px 23px;
	color: transparent;
	display: block;
	font: 0/0 serif;
	height: 23px;
	left: 0;
	overflow: hidden;
	position: absolute;
	text-shadow: none;
	top: 0;
	width: 23px;
}

.map-controls .zoom-in {
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjQ1cHgiIGhlaWdodD0iNDVweCIgdmlld0JveD0iMCAwIDQ1IDQ1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjguMyAoMjk4MDIpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPnN5c3RlbS1wbHVzLWxhcmdlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkRlZmF1bHQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxOTIwcHgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNTA1LjAwMDAwMCwgLTIyMC4wMDAwMDApIiBmaWxsPSIjMDAwMDAwIj4KICAgICAgICAgICAgPGcgaWQ9InN5c3RlbS1wbHVzLWxhcmdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTA1LjAwMDAwMCwgMjIwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTIyLjUsMCBDMTAuMDkzLDAgMCwxMC4wOTMgMCwyMi41IEMwLDM0LjkwNiAxMC4wOTMsNDUgMjIuNSw0NSBDMzQuOTA2LDQ1IDQ1LDM0LjkwNiA0NSwyMi41IEM0NSwxMC4wOTMgMzQuOTA2LDAgMjIuNSwwIEwyMi41LDAgWiBNMjIuNSw0NCBDMTAuNjQ1LDQ0IDEsMzQuMzU1IDEsMjIuNSBDMSwxMC42NDUgMTAuNjQ1LDEgMjIuNSwxIEMzNC4zNTUsMSA0NCwxMC42NDUgNDQsMjIuNSBDNDQsMzQuMzU1IDM0LjM1NSw0NCAyMi41LDQ0IEwyMi41LDQ0IFoiIGlkPSJGaWxsLTEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iRmlsbC0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMi41MDAwMDAsIDIyLjQ2MDAwMCkgcm90YXRlKDQ1LjAwMDAwMCkgdHJhbnNsYXRlKC0yMi41MDAwMDAsIC0yMi40NjAwMDApICIgcG9pbnRzPSIyOC4wODQgMTcuNTEzIDI3LjM3NiAxNi44MDYgMjIuNDY1IDIxLjcxOCAxNy42MjQgMTYuODc3IDE2LjkxNyAxNy41ODQgMjEuNzU4IDIyLjQyNSAxNi44NDYgMjcuMzM2IDE3LjU1MyAyOC4wNDQgMjIuNDY1IDIzLjEzMiAyNy40NDcgMjguMTE0IDI4LjE1NCAyNy40MDcgMjMuMTcyIDIyLjQyNSAyOC4wODQgMTcuNTEzIj48L3BvbHlsaW5lPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
}

.map-controls .zoom-out {
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjQ1cHgiIGhlaWdodD0iNDVweCIgdmlld0JveD0iMCAwIDQ1IDQ1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjguMyAoMjk4MDIpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPnN5c3RlbS1wbHVzLWxhcmdlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkRlZmF1bHQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxOTIwcHgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNDM5LjAwMDAwMCwgLTIyMC4wMDAwMDApIiBmaWxsPSIjMDAwMDAwIj4KICAgICAgICAgICAgPGcgaWQ9InN5c3RlbS1wbHVzLWxhcmdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNDM5LjAwMDAwMCwgMjIwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTIyLjUsMCBDMTAuMDkzLDAgMCwxMC4wOTMgMCwyMi41IEMwLDM0LjkwNiAxMC4wOTMsNDUgMjIuNSw0NSBDMzQuOTA2LDQ1IDQ1LDM0LjkwNiA0NSwyMi41IEM0NSwxMC4wOTMgMzQuOTA2LDAgMjIuNSwwIEwyMi41LDAgWiBNMjIuNSw0NCBDMTAuNjQ1LDQ0IDEsMzQuMzU1IDEsMjIuNSBDMSwxMC42NDUgMTAuNjQ1LDEgMjIuNSwxIEMzNC4zNTUsMSA0NCwxMC42NDUgNDQsMjIuNSBDNDQsMzQuMzU1IDM0LjM1NSw0NCAyMi41LDQ0IEwyMi41LDQ0IFoiIGlkPSJGaWxsLTEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIyLjUwMDAwMCwgMjIuNDEwNTAzKSByb3RhdGUoNDUuMDAwMDAwKSB0cmFuc2xhdGUoLTIyLjUwMDAwMCwgLTIyLjQxMDUwMykgIiBwb2ludHM9IjI3LjQxMSAxNi43OTE1MDI1IDIyLjUgMjEuNzAzNTAyNSAyMS43OTMgMjIuNDEwNTAyNSAxNi44ODEgMjcuMzIxNTAyNSAxNy41ODggMjguMDI5NTAyNSAyMi41IDIzLjExNzUwMjUgMjMuMjA3IDIyLjQxMDUwMjUgMjguMTE5IDE3LjQ5ODUwMjUiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
}

.map-controls > button:active,
.map-controls > button:focus {
	border: none;
	outline: none;
}

.map-ask-mode-values {
	color: #c03;
	font-weight: var(--font-weight-bold);
}

.axs-map-labels {
	background-color: var(--color-white);
	display: none !important;
	padding: 5px 10px;
}

@media all and (min-width: 768px) {
	.map-partner-details {
		background: var(--color-white);
		height: 0;
		max-width: 250px;
		opacity: 0;
		overflow: auto;
		position: absolute;
		top: 20px;
		transform: translateZ(0);
		transition: opacity .3s ease-in-out;
		width: calc(70% - 20px);
		z-index: 200;
	}

	.map-controls > button {
		height: 45px;
		width: 45px;
	}

	.map-controls > button > span {
		background-size: 45px 45px;
		height: 45px;
		width: 45px;
	}

	.map-detail-main {
		padding: 20px;
	}

	.map-detail-header img {
		display: block;
	}

	.map-detail-header .axs-button-close {
		display: block;
	}

	.axs-map-labels {
		display: block !important;
	}
}
