/* BEGIN CSS-CLASSES FOR THE RTE */

.axs-rte * {
	background: none !important;
	border: none !important;
	bottom: auto !important;
	clear: none !important;
	cursor: inherit !important;
	float: none !important;
	font: inherit !important;
	font-family: inherit !important;
	font-size: inherit !important;
	font-style: inherit !important;
	font-weight: inherit !important;
	height: auto !important;
	left: auto !important;
	letter-spacing: inherit !important;
	line-height: inherit !important;
	margin: 0 !important;
	max-height: none !important;
	max-width: none !important;
	min-height: 0 !important;
	min-width: 0 !important;
	overflow: visible !important;
	padding: 0 !important;
	position: static !important;
	right: auto !important;
	text-align: left !important;
	text-decoration: none !important;
	text-indent: 0 !important;
	text-transform: none !important;
	top: auto !important;
	visibility: visible !important;
	white-space: normal !important;
	width: auto !important;
	z-index: auto !important;
}

.axs-rte ul,
.axs-rte ol {
	display: block !important;
}

.axs-rte ul {
	list-style-position: outside;
	list-style-type: disc;
}

.axs-rte ol {
	list-style-position: outside;
	list-style-type: decimal;
}

.axs-rte li {
	display: list-item !important;
	margin-left: 18px !important;
	padding-left: 10px !important;
}

.axs-rte strong,
.axs-rte b {
	font-family: AudiTypeBold, sans-serif !important;
}

.axs-rte a {
	color: var(--color-grey-70);
	text-decoration: underline !important;
}

.axs-rte a:hover,
.axs-rte a:active {
	color: var(--color-black);
}

/* END CSS-CLASSES FOR THE RTE */
.axs-md-editorial-pos-abs {
	position: absolute;
}

.axs-md-editorial-image-headline {
	margin-top: 40px;
	z-index: 2;
}

.axs-md-editorial-head-pos {
	margin-bottom: 26px;
	position: relative;
	width: 100%;
}

.axs-md-editorial-head-pos-with-large-image {
	height: 0;
	margin-bottom: 30px;
	padding-bottom: 31.25%;
}

.axs-md-editorial-image-large {
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.axs-md-editorial-headline-no-image-margin-bottom {
	margin-bottom: -10px;
}

.axs-md-editorial-play-button {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpDQkI3OTZGMDExMjA2ODExODhDNjhGRkY5MUNDMzY5QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MDdENTRGQTMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MDdENTRGOTMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRBNUMxRDU4MkUyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNCQjc5NkYwMTEyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Eb1iGwAAALhJREFUeNrs2ssNgCAQhGEwVqH9l2YdSAke2JmR/HvmwBdgHwl9jHG1DeNomwYwYMCAAQP25zi/Luy9Pymbnt3SzVUEBgwYsCjYTM37npgDJ7uKapz0jSlx8uShwlmyogJnS/fVOGsdq8TZC3QVLqLzqMDFtFSrcTGwOaHvB1uNioBVoOywKpQVVomywapRFpgCJYepUFKYEiWDqVESmAMV1VIBAwYMWMZ8xycxYMCAAQMGLC9eAQYA3nsrm9Al1AgAAAAASUVORK5CYII=");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	cursor: pointer;
	display: block;
	height: 55px;
	left: 50%;
	position: absolute;
	right: inherit;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 55px;
}

.axs-md-editorial-play-button:hover {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpDQkI3OTZGMDExMjA2ODExODhDNjhGRkY5MUNDMzY5QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MDdENTRGRTMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MDdENTRGRDMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRBNUMxRDU4MkUyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNCQjc5NkYwMTEyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+18HAYgAAAMFJREFUeNrs2rERwyAQRFHwuAqrHafKXKYyp27HdSCVoEC3u2L+xgS8AY5jhj7GeLUJ82iTBhgwYMCAAbtznmcHvtfPP2XSv++2sBWBAQMGLAp2lOZ5V8yBk21FNU56xpQ4efFQ4SxVUYGzlftqnPUeq8TZL+gqXETnUYGLaamuxsXAjhf6fLCrURGwCpQdVoWywipRNlg1ygJToOQwFUoKU6JkMDVKAnOgoloqYMCAAYtI55MYMGDAgAEDlpddgAEAyMsrm5z8Ei8AAAAASUVORK5CYII=");
}

.axs-md-editorial-showmore,
.axs-md-editorial-showless {
	clear: both;
	float: right;
	margin-top: 40px;
	text-align: right;
}

.axs-md-editorial-showless {
	display: none;
}

.axs-md-editorial-link-pos {
	clear: left;
	margin-left: 69.5%;
}

.axs-md-editorial .axs-md-editorial-link {
	clear: left;
	float: left;
	margin-bottom: 15px;
}

.axs-md-editorial .axs-md-editorial-link:last-child {
	margin-bottom: 0;
}

.axs-md-editorial-head-pos h1 {
	clear: both;
	margin-top: 35px;
}

.axs-md-editorial-image-headline-white {
	color: var(--color-white);
}

.axs-md-editorial-image-headlinebg-white {
	background-color: var(--color-white);
	padding: 10px;
}

.axs-md-editorial-image-headlinebg-black {
	background-color: var(--color-black);
	padding: 10px;
}

.axs-md-editorial h2 {
	clear: both;
	margin-bottom: 17px;
	width: 70%;
}

.axs-md-editorial img {
	width: 100%;
}

.axs-md-editorial .axs-column-1 {
	width: 74%;
}

.axs-md-editorial .axs-column-2 {
	margin-right: 4%;
}

.axs-md-editorial .axs-column-2:nth-of-type(2n) {
	margin-right: 0%;
}

.axs-md-editorial .axs-column-3 {
	content: " ";
	margin-bottom: 50px;
	margin-right: 4%;
	overflow-y: hidden;
	position: relative;
	width: 30.66%;
}

.axs-md-editorial-content-items {
	clear: both;
}

.axs-md-editorial-hidden-none {
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: opacity 1s ease-in-out;
}

.axs-md-editorial-margin-0 {
	clear: right;
	margin-right: 0 !important;
}

input.axs-md-editorial-ci-input {
	display: none;
	visibility: hidden;
}

input.axs-md-editorial-ci-input:checked ~ .axs-md-editorial-hidden-none {
	height: auto;
	opacity: 1;
	overflow: visible;
}

input.axs-md-editorial-ci-input:checked ~ .axs-md-editorial-show-buttons .axs-md-editorial-showmore {
	display: none;
}

input.axs-md-editorial-ci-input:checked ~ .axs-md-editorial-show-buttons .axs-md-editorial-showless {
	display: block;
}

.axs-md-editorial-column-4 {
	clear: left;
}

@media all and (min-width: 1025px) {
	.axs-md-editorial-margin-bottom-0 {
		margin-bottom: 0;
	}

}

/* TABLET 769px - 1024px */

@media all and (min-width: 768px) and (max-width: 1024px) {
	.axs-md-editorial .axs-column-3 {
		margin-right: 4%;
		width: 48%;
	}

	.axs-md-editorial-hidden-none > .axs-column-3:nth-of-type(odd),
	.axs-inner-content > .axs-column-3:nth-of-type(2n) {
		margin-right: 0%;
	}

	.axs-md-editorial-hidden-none > .axs-column-3:nth-of-type(even) {
		float: left;
	}

	.axs-md-editorial-hidden-none > .axs-column-3:nth-of-type(odd) {
		clear: none;
		float: right;
	}

	.axs-md-editorial-margin-0 {
		clear: both;
	}

	.axs-md-editorial-column-3 {
		float: left;
	}

	.axs-md-editorial-column-4 {
		clear: none;
	}

	.axs-md-editorial-column-5 {
		clear: both;
	}

	.axs-md-editorial-play-button {
		/* top: 12%; */
	}
}

/* MOBILE 320px - 768px */

@media all and (min-width: 0) and (max-width: 767px) {
	.axs-md-editorial .axs-column-3 {
		width: 100%;
	}

	.axs-md-editorial .axs-column-3:last-child {
		content: " ";
		margin-bottom: 0;
		margin-right: 4%;
		overflow-y: hidden;
		position: relative;
		width: 30.66%;
	}

	.axs-md-editorial-head-pos h1 {
		font-size: 26px;
		line-height: 28px;
	}

	.axs-md-editorial img {
		height: 100%;
		width: 100%;
	}
}
