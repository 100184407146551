.axs-accordion {
	display: block;
	width: 100%;
}

.axs-accordion__item {
	border-top: 1px solid var(--color-grey-05);
}

.axs-accordion__item:last-of-type {
	border-bottom: 1px solid var(--color-grey-05);
}

.axs-accordion__item-header {
	cursor: pointer;
	display: flex;
	font-weight: var(--font-weight-bold);
	padding: 18px 0;
}

.axs-accordion__item-label {
	flex-grow: 1;
}

.axs-accordion__item-icon-wrapper {
	display: block;
	flex-grow: 0;
	height: var(--icon-size-small);
	margin-left: 12px;
	margin-right: 6px;
}

.axs-accordion__item-icon {
	transform: rotate(180deg);
}

.axs-accordion__item-content {
	margin: 18px;
}

.axs-accordion__item-content-wrapper {
	background-color: var(--color-grey-05);
	height: auto;
	overflow: hidden;
	transform: scaleY(1);
	transform-origin: top;
	transition: transform .1s ease-out;
}

.axs-accordion__item[is-closed="true"] .axs-accordion__item-content-wrapper {
	height: 0;
	transform: scaleY(0);
}

.axs-accordion__item[is-closed="true"] .axs-accordion__item-header {
	font-weight: var(--font-weight-normal);
}

.axs-accordion__item[is-closed="true"] .axs-accordion__item-icon-wrapper {
	transform: rotate(180deg);
}

@media all and (min-width: 768px) {
	.axs-accordion__item-icon-wrapper {
		margin-left: var(--space-xl);
	}

	.axs-accordion__item-content {
		margin: var(--space-xl);
	}
}
