.axs-ondemand-configurator__pagination {
	display: block;
	padding-top: var(--space-xxl);
}

.axs-ondemand-configurator__pagination--hidden {
	display: none;
}

.axs-ondemand-configurator__pagination-body {
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	padding: 0 calc(var(--icon-size-small) + var(--space-m)); /* button width + margin of button */
	position: relative;
}

.axs-ondemand-configurator__pagination-inner-wrapper {
	width: 100%; /* container needed for centerSelectedItem calculation */
}

/* gradients */

.axs-ondemand-configurator__pagination-body::before,
.axs-ondemand-configurator__pagination-body::after {
	bottom: 0;
	content: "";
	display: block;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: calc(var(--icon-size-small) + var(--space-m)); /* button width + margin of button */
	z-index: 2;
}

.axs-ondemand-configurator__pagination-body::before {
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
	left: 0;
}

.axs-ondemand-configurator__pagination-body::after {
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
	right: 0;
}

/* headline */
.axs-ondemand-configurator__pagination-headline-wrapper {
	display: none;
}

.axs-ondemand-configurator__pagination-headline {
	font-weight: var(--font-weight-bold);
}

/* pagination buttons */

.axs-ondemand-configurator__pagination-button {
	appearance: none;
	bottom: 0;
	display: block;
	height: var(--icon-size-small);
	position: absolute;
	top: 0;
	width: var(--icon-size-small);
	z-index: 5;
}

.axs-ondemand-configurator__pagination-button:hover {
	color: var(--color-grey-70);
}

.axs-ondemand-configurator__pagination-button--backwards {
	left: 0;
}

.axs-ondemand-configurator__pagination-button--forwards {
	right: 0;
}

.axs-ondemand-configurator__pagination-button:disabled {
	color: var(--color-black);
	cursor: default;
	opacity: .5;
}

.axs-ondemand-configurator__pagination-button-icon {
	display: block;
	margin: auto;
}

.axs-ondemand-configurator__pagination-button-icon--backwards {
	transform: rotate(90deg);
}

.axs-ondemand-configurator__pagination-button-icon--forwards {
	transform: rotate(-90deg);
}

/* list/ list entries */

.axs-ondemand-configurator__pagination-total,
.axs-ondemand-configurator__pagination-devider {
	display: none;
}

.axs-ondemand-configurator__pagination-list {
	display: flex;
	flex-wrap: nowrap;
	position: relative;
	transition: left 1s;
}

.axs-ondemand-configurator__pagination-entry {
	line-height: 0; /* prevent extra space under active line */
	max-width: calc(92vw);
	min-width: calc(92vw);
	padding: 0 calc(var(--icon-size-small) + var(--space-m)); /* button width + margin of button */
	text-align: center;
}

.axs-ondemand-configurator__pagination-entry--hidden {
	display: none;
}

/* entry content */

.axs-ondemand-configurator__pagination-index {
	display: none;
}

.axs-ondemand-configurator__pagination-name {
	display: inline-block;
	font-weight: var(--font-weight-bold);
	line-height: var(--icon-size-small); /* center icon and text */
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;
}

.axs-ondemand-configurator__pagination-entry--active .axs-ondemand-configurator__pagination-name {
	border-bottom: 1px solid var(--color-black);
}

/* large version */

@media all and (min-width: 1024px) {
	.axs-ondemand-configurator__pagination-body {
		display: block;
		padding: 0 var(--icon-size-large) var(--space-l);
	}

	.axs-ondemand-configurator__pagination-wrapper {
		align-items: center;
		display: flex;
		height: 28px;
		justify-content: center;
	}

	/* gradients */

	.axs-ondemand-configurator__pagination-body::before,
	.axs-ondemand-configurator__pagination-body::after {
		display: none;
	}

	/* headline */

	.axs-ondemand-configurator__pagination-headline-wrapper {
		align-items: center;
		display: flex;
		flex-direction: row;
		margin-bottom: var(--space-xs);
		text-align: center;
	}

	/*
		IE 11 Fix for centering content in flex-container with min-height (headline in headline-wrapper)
	*/
	.axs-ondemand-configurator__pagination-headline-wrapper::after {
		content: "";
		display: block;
		min-height: var(--icon-size-large);
		width: 0;
	}

	.axs-ondemand-configurator__pagination-headline {
		display: block;
		text-align: center;
		width: 100%;
	}

	/* pagination buttons */

	.axs-ondemand-configurator__pagination-button {
		position: static;
	}

	.axs-ondemand-configurator__pagination-button--forwards {
		margin-left: var(--space-m);
	}

	.axs-ondemand-configurator__pagination-button--backwards {
		margin-right: var(--space-m);
	}

	/* list/ list entries */

	.axs-ondemand-configurator__pagination-total,
	.axs-ondemand-configurator__pagination-devider {
		color: var(--color-black);
		display: block;
		opacity: .5;
	}

	.axs-ondemand-configurator__pagination-list {
		display: inline-block;
		position: static;
	}

	.axs-ondemand-configurator__pagination-entry {
		display: none;
	}

	.axs-ondemand-configurator__pagination-entry--active {
		display: block;
		min-width: 0;
		padding: 0;
		width: auto;
	}

	/* entry content */

	.axs-ondemand-configurator__pagination-index {
		display: block;
	}

	.axs-ondemand-configurator__pagination-name {
		display: none;
	}
}
