/**
 * @project AUDI ACCESS (AXS)
 * @file modules/packageinformation.css
 *
 * @description
 *
 * Styles for the package information module layout
 *
 * @TODO
 *
 */

/* @charset "UTF-8"; */

.axs-md-pi-details-border-div-1,
.axs-md-pi-details-border-div-2 {
	border-left: 1px solid #d0d3d4;
	padding-left: 20px;
}

.axs-md-pi-details-border-div-3 {
	border-right: 1px solid #d0d3d4;
	padding-right: 20px;
}

.axs-md-packageinformation {
	position: relative;
}

.axs-md-pi-details .axs-el-pg-03:not(.axs-md-pi-details-Copy) {
	line-height: 1.5rem;
}

.axs-md-pi-details .axs-el-hdl-04 {
	line-height: 1rem;
}

.axs-md-packageinformation .axs-el-pg + .axs-el-hdl {
	margin-top: 0;
}

.axs-md-packageinformation .axs-column-3 {
	margin-right: 4%;
	/* margin-top: 20px; */
	padding-top: 20px;
	width: 30%;
}

.axs-md-packageinformation-2 {
	margin-right: 8%;
}

.axs-md-packageinformation .axs-column-3:last-child {
	border-right: none;
	margin-right: 0;
}

.axs-md-packageinformation-1,
.axs-md-packageinformation-2,
.axs-md-packageinformation-3 {
	overflow: hidden;
}

.border-div-right:last-child {
	display: none;
}

.axs-md-pi-basics-with-btn-1,
.axs-md-pi-basics-with-btn-2 {
	border-right: 1px solid #d0d3d4;
	padding-right: 10%;
}

.axs-md-pi-headline {
	margin-bottom: 30px;
}

.axs-md-pi-headline.axs-at-hdl-u::after {
	margin-bottom: 0;
}

.axs-md-pi-headline-text {
	margin-bottom: 60px;
	width: 83%;
}

.axs-md-packageinformation img {
	clear: left;
	/* margin-bottom: 20px; */
	max-height: 80px;
	max-width: 400px;
}

.axs-md-packageinformation h4 {
	margin: 0 0 20px;
	margin-top: 20px;
}

.axs-md-packageinformation-table {
	display: table;
	margin-bottom: 60px;
	position: relative;
	width: 100%;
}

.axs-md-pi-details-Copy {
	margin-top: 0;
	padding-right: 10px;
}

.axs-md-pi-details-1 .axs-md-pi-details-Copy {
	margin-bottom: 0;
	margin-left: -20px;
	margin-top: 0;
	padding-left: 20px;
	padding-right: 10px;
}

.axs-md-pi-details-2 .axs-md-pi-details-Copy {
	margin-bottom: 0;
	margin-left: -20px;
	margin-top: 0;
	padding-left: 20px;
	padding-right: 10px;
}

.axs-md-pi-details-3 .axs-md-pi-details-Copy {
	margin: 0 -20px;
	padding-left: 20px;
	padding-right: 10px;
}

.axs-md-pi-details-SubCopy {
	font-weight: var(--font-weight-bold);
	margin-top: 10px;
}

h4.axs-md-pi-details-PriceHtml {
	margin-bottom: 10px;
}

.axs-md-pi-details-SublinePrice {
	margin-top: -9px;
}

.axs-md-pi-basics-1
.axs-md-pi-basics-2,
.axs-md-pi-basics-3 {
	clear: both;
	float: left;
	width: 80%;
}

.axs-md-pi-basics-btn {
	float: left;
}

div.axs-md-packageinformation-table input[type="radio"] {
	display: none;
}

.axs-md-pi-details-button {
	height: 25px;
	overflow: hidden;
	position: absolute;
	right: 5px;
	top: 10px;
	width: 25px;
}

.axs-md-pi-basics-btn-portable-1,
.axs-md-pi-basics-btn-portable-2,
.axs-md-pi-basics-btn-portable-3 {
	content: "";
	cursor: pointer;
	float: right;
	height: 25px;
	position: relative;
	transform: rotate(90deg);
	width: 25px;
	z-index: 2;
}

input.axs-md-pi-details-checkbox-1:checked ~ div.axs-md-pi-details-1 {
	display: block;
}

input.axs-md-pi-details-checkbox-2:checked ~ div.axs-md-pi-details-2 {
	display: block;
}

input.axs-md-pi-details-checkbox-3:checked ~ div.axs-md-pi-details-3 {
	display: block;
}

input.axs-md-pi-details-checkbox-1:checked ~ .axs-md-pi-basics-btn-portable-1,
input.axs-md-pi-details-checkbox-2:checked ~ .axs-md-pi-basics-btn-portable-2,
input.axs-md-pi-details-checkbox-3:checked ~ .axs-md-pi-basics-btn-portable-3 {
	transform: rotate(270deg) scaleY(-1);
}

input.axs-md-pi-details-checkbox-1,input.axs-md-pi-details-checkbox-2,input.axs-md-pi-details-checkbox-3 {
	display: none;
}

.border-div-right {
	display: none;
}

.axs-md-pi-details-PriceHtml {
	margin-top: 17px !important;
}

.axs-md-pi-details-SubLinkText {
	margin-top: 17px;
}

/* DESKTOP */

@media all and (min-width: 1025px) {
	.axs-md-packageinformation {
		position: relative;
	}

	.axs-md-packageinformation img {
		position: absolute;
	}

	.axs-md-packageinformation h4 {
		/* margin: 80px 0 20px; */
		/* margin: 100px 0 20px; */
		margin: 90px 0 15px;
	}

	.axs-md-packageinformation-1,
	.axs-md-packageinformation-2 {
		border-right: 1px solid #d0d3d4;
	}

	.axs-md-pi-details {
		background-color: var(--color-white);
		clear: both;
		float: right;
		left: 15%;
		opacity: 0;
		padding: 30px 0 20px;
		position: absolute;
		top: 0;
		transition-duration: 500ms;
		visibility: hidden;
		width: 30%;
		z-index: -1000;
	}

	.axs-md-pi-details-2 {
		left: 45%;
		padding-left: 20px;
	}

	.axs-md-pi-details-3 {
		left: 40%;
		padding-left: 20px;
	}

	label.axs-md-pi-basics-1 {
		padding-left: 0;
		transition-duration: 500ms;
	}

	.axs-md-pi-basics-btn-1 {
		margin-left: 0;
		transition-duration: 500ms;
	}

	label.axs-md-pi-basics ~ div.axs-md-pi-details,
	input.axs-md-pi-basics-radio ~ div.axs-md-pi-details {
		z-index: 1;
	}

	label.axs-md-pi-basics:hover ~ div.axs-md-pi-details,
	input.axs-md-pi-basics-radio:checked ~ div.axs-md-pi-details {
		opacity: 1;
		visibility: visible;
	}

	input.axs-md-pi-basics-radio:checked ~ .axs-md-pi-basics {
		transition-duration: 500ms;
	}

	input.axs-md-pi-basics-radio:checked ~ .axs-md-pi-basics-btn {
		transition-duration: 500ms;
	}

	label.axs-md-pi-basics:hover {
		transition-duration: 500ms;
	}

	label.axs-md-pi-basics:hover ~ .axs-md-pi-basics-btn {
		transition-duration: 500ms;
	}

	/* **************LABEL 1*** */

	label.axs-md-pi-basics-1:hover ~ div.axs-md-pi-details-1 {
		left: 32%;
		width: 30%;
	}

	input.axs-md-pi-basics-radio:checked ~ label,
	input.axs-md-pi-basics-radio:checked ~ .axs-md-pi-basics-btn {
		position: relative;
		z-index: 2;
	}

	input.axs-md-pi-basics-radio:checked ~ div.axs-md-pi-details::after {
		border: 1px solid #d0d3d4;
		content: "";
		height: 100%;
		left: -107%;
		position: absolute;
		speak: none;
		top: 0;
		width: 207%;
		z-index: -1;
	}

	input.axs-md-pi-basics-radio-2:checked ~ div.axs-md-pi-details-2::after {
		left: -100%;
		width: 200%;
	}

	input.axs-md-pi-basics-radio-3:checked ~ div.axs-md-pi-details-3::after {
		left: 0;
	}

	input.axs-md-pi-basics-radio-1:checked ~ div.axs-md-pi-details-1 {
		left: 32%;
		width: 30%;
	}

	input.axs-md-pi-basics-radio-1:checked ~ .axs-md-pi-basics-1 {
		padding-left: 20px;
	}

	input.axs-md-pi-basics-radio-1:checked ~ .axs-md-pi-basics-btn-1 {
		margin-left: 20px;
	}

	label.axs-md-pi-basics-1:hover {
		padding-left: 20px;
	}

	label.axs-md-pi-basics-1:hover ~ .axs-md-pi-basics-btn-1 {
		margin-left: 20px;
	}

	/* **************LABEL 2*** */

	label.axs-md-pi-basics-2:hover ~ div.axs-md-pi-details-2 {
		left: 62%;
		width: 30%;
	}

	input.axs-md-pi-basics-radio-2:checked ~ .axs-md-pi-details-2 {
		left: 62%;
		width: 30%;
	}

	/* **************LABEL 3*** */
	label.axs-md-pi-basics-3:hover ~ div.axs-md-pi-details-3 {
		left: 34%;
		opacity: 1;
		visibility: visible;
		width: 30%;
		z-index: 1;
	}

	input.axs-md-pi-basics-radio-3:checked ~ .axs-md-pi-details-3 {
		left: 34%;
		width: 30%;
	}

	.axs-md-packageinformation label {
		cursor: pointer;
	}

	.axs-md-pi-basics-btn-portable-1,
	.axs-md-pi-basics-btn-portable-2,
	.axs-md-pi-basics-btn-portable-3 {
		display: none;
	}

	.axs-md-packageinformation-1,
	.axs-md-packageinformation-2,
	.axs-md-packageinformation-3 {
		background-color: var(--color-white);
	}
}

@media all and (max-width: 1024px) {
	.axs-md-packageinformation-1,
	.axs-md-packageinformation-2,
	.axs-md-packageinformation-3 {
		position: relative;
	}

	.axs-md-pi-basics-btn-portable-1,
	.axs-md-pi-basics-btn-portable-2,
	.axs-md-pi-basics-btn-portable-3,
	.axs-md-pi-basics-btn-portable-no-button-left {
		bottom: auto;
		position: absolute;
		right: 0;
		top: 120px;
	}
}

/* TABLET */

@media all and (min-width: 769px) and (max-width: 1024px) {
	.axs-md-pi-details-border-div-1,
	.axs-md-pi-details-border-div-2 {
		border-left: none;
		padding-left: 0;
	}

	.axs-md-pi-details-border-div-3 {
		border-right: none;
	}

	.axs-md-packageinformation {
		height: auto;
		position: relative;
	}

	.axs-md-packageinformation img {
		position: absolute;
	}

	.axs-md-packageinformation h4 {
		hyphens: auto;
		margin: 100px 0 20px;
		-ms-word-break: break-all;
		word-wrap: break-word;
	}

	.border-div-right {
		display: block;
	}

	input.axs-md-pi-details-checkbox:checked ~ div.axs-md-pi-details {
		opacity: 1;
		visibility: visible;
	}

	.axs-md-packageinformation-table {
		margin-bottom: 35px;
	}

	.axs-md-pi-headline-text {
		width: 100%;
	}

	.axs-md-pi-details {
		background-color: transparent;
		display: none;
		float: left;
		left: 0;
		margin-top: 25px;
		padding-left: 0;
		position: relative;
		top: 0;
		z-index: 0;
	}

	.border-div-1,
	.border-div-2,
	.border-div-3 {
		border: none;
		display: none;
	}

	.axs-md-pi-details.axs-column-3 {
		margin-top: 30px;
		width: auto;
	}

	.axs-md-pi-details-button {
		display: none;
	}

	.axs-md-pi-basics-btn {
		clear: both;
		float: left;
	}

	.show-details-1
	.show-details-2,
	.show-details-3 {
		display: none;
	}

	input.axs-md-pi-basics-radio-3:checked ~ .axs-md-pi-details-3 {
		opacity: 1;
		visibility: visible;
	}

	.axs-md-pi-details-1 .axs-md-pi-details-Copy,
	.axs-md-pi-details-2 .axs-md-pi-details-Copy {
		margin-bottom: 0;
		margin-left: -20px;
		margin-top: 0;
		padding-left: 20px;
		padding-right: 10px;
	}

	.axs-md-pi-details-1,
	.axs-md-pi-details-2,
	.axs-md-pi-details-3,
	.axs-md-packageinformation-1,
	.axs-md-packageinformation-2,
	.axs-md-packageinformation-3 {
		height: auto !important;
		min-height: auto !important;
	}

}

/* MOBILE */

@media all and (min-width: 0) and (max-width: 768px) {
	.axs-md-pi-details-border-div-1,
	.axs-md-pi-details-border-div-2 {
		border-left: none;
		padding-left: 0;
	}

	.axs-md-pi-details-border-div-3 {
		border-right: none;
	}

	.axs-md-packageinformation img {
		clear: left;
		max-height: 80px;
		max-width: 400px;
	}

	.axs-md-packageinformation {
		height: auto;
		position: relative;
	}

	input.axs-md-pi-details-checkbox:checked ~ div.axs-md-pi-details {
		opacity: 1;
		visibility: visible;
	}

	.axs-md-pi-details {
		background-color: none;
		clear: left;
		display: none;
		float: left;
		left: 0;
		margin-top: 25px;
		padding-left: 0;
		position: relative;
		top: 0;
		z-index: 0;
	}

	.axs-md-pi-details-1,
	.axs-md-pi-details-2,
	.axs-md-pi-details-3 {
		height: auto !important;
		min-height: auto !important;
	}

	.axs-md-packageinformation .axs-column-3 {
		border-right: none !important;
		clear: left;
		float: left;
		margin-right: 4%;
		width: 100%;
	}

	.axs-md-packageinformation-1,
	.axs-md-packageinformation-2,
	.axs-md-packageinformation-3 {
		border-bottom: 1px solid #d0d3d4;
		border-right: 0;
		height: auto !important;
		min-height: auto !important;
		padding-bottom: 30px;
		padding-top: 30px;
		position: relative;
	}

	.axs-md-gray-border-bottom-line-up {
		padding-bottom: 10px;
	}

	.axs-md-pi-details-button {
		display: none;
	}

	.axs-md-pi-basics-btn {
		clear: left;
		float: left;
	}

	.border-div-right {
		display: none;
	}

	.show-details-1,
	.show-details-2,
	.show-details-3 {
		display: none;
	}

	.axs-md-packageinformation-1 {
		margin-top: 0;
		padding-top: 0;
	}

	.axs-md-pi-details-1 .axs-md-pi-details-Copy,
	.axs-md-pi-details-2 .axs-md-pi-details-Copy {
		margin-bottom: 0;
		margin-left: -20px;
		margin-top: 0;
		padding-left: 20px;
		padding-right: 10px;
	}

}
