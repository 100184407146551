.audi-axs-table {
	display: flex;
	flex-flow: row wrap;
	font-size: 0;
	padding: var(--space-xl) 4% 0;
}

.axs-table-item {
	border-top: 1px solid var(--color-black);
	display: inline-block;
	padding: var(--space-s) 0 var(--space-l);
	width: 100%;
}

.axs-table-item-label {
	font-weight: var(--font-weight-bold);
	margin-bottom: 0;
}

.axs-table-item-value {
	border-top-width: 0;
	padding-top: var(--space-s);
}

.axs-table-item-value a {
	text-decoration: underline;
}

.axs-table-item-value a:hover {
	color: var(--color-grey-70);
}

@media all and (min-width: 472px) {
	.axs-table-item {
		border-top-width: 3px;
		padding-bottom: var(--space-l);
		width: calc(50% - (var(--space-m) / 2));
	}

	.axs-table-item:nth-child(2n) {
		margin-left: var(--space-m);
	}

	.axs-table-item-label {
		margin-bottom: var(--space-l);
	}

	.axs-table-item-value {
		border-top: 1px solid var(--color-black);
	}
}

@media all and (min-width: 768px) {
	.axs-table-item {
		margin-left: var(--space-m);
		width: calc(33% - (var(--space-m) * (2 / 3)));
	}

	.axs-table-item:nth-child(3n-2) {
		margin-left: 0;
	}

	.audi-axs-table[data-column-number="2"] .axs-table-item {
		width: calc(50% - (var(--space-m) / 2));
	}
}

@media all and (min-width: 1024px) {
	.axs-table-item {
		width: calc(25% - (var(--space-m) * (3 / 4)));
	}

	.audi-axs-table[data-column-number="2"] .axs-table-item,
	.audi-axs-table[data-column-number="3"] .axs-table-item,
	.audi-axs-table[data-column-number="4"] .axs-table-item,
	.audi-axs-table[data-column-number="5"] .axs-table-item {
		margin-left: var(--space-m);
	}

	.audi-axs-table .axs-table-item:nth-child(4n-3) {
		margin-left: 0;
	}

	.audi-axs-table[data-column-number="2"] .axs-table-item,
	.audi-axs-table[data-column-number="3"] .axs-table-item {
		width: calc(33% - (var(--space-m) * (2 / 3)));
	}
}

@media all and (min-width: 1175px) {
	.audi-axs-table[data-column-number="5"] .axs-table-item {
		margin-left: var(--space-m);
		width: calc(20% - (var(--space-m) * (4 / 5)));
	}

	.audi-axs-table[data-column-number="5"] .axs-table-item:nth-child(5n-4) {
		margin-left: 0;
	}
}
