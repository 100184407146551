.axs-headline-module {
	padding-bottom: var(--space-xxxl);
	padding-top: var(--space-xxl);
}

.axs-headline-module__headline {
	font-weight: var(--font-weight-bold);
	text-align: center;
}

.axs-headline-module__headline--big + .axs-headline-module__subline {
	margin-top: var(--space-m);
}

.axs-headline-module__headline--medium + .axs-headline-module__subline {
	margin-top: var(--space-s);
}

.axs-headline-module__headline--small + .axs-headline-module__subline {
	margin-top: var(--space-xs);
}

/* themes */

.axs-headline-module[data-theme="dark"] {
	background-color: var(--color-black);
}
