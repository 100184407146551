.axs-button-combined {
	padding-bottom: calc(var(--space-xxxl) - var(--space-xs)); /* subtract margin of last link */
	padding-top: var(--space-xxxl);
}

.axs-button-combined[data-theme="dark"] {
	background-color: var(--color-black);
	color: var(--color-white);
}

.axs-button-combined__link-wrapper--align-right {
	display: flex;
	justify-content: flex-end;
}

.axs-button-combined__link-wrapper--align-centered {
	display: flex;
	justify-content: center;
}

.axs-button-combined__link {
	margin-bottom: var(--space-xs);
}

.axs-button-combined__link--button {
	max-width: 100%;
	width: 100%;
}

.axs-button-combined[data-display-type="Text-Text"] .axs-button-combined__link-wrapper {
	margin-right: var(--space-xxl); /* add space for the generic margin of the button */
}

.axs-button-combined[data-display-type="Text-Text"] .axs-button-combined__link--text {
	margin-right: var(--space-xxl);
}

@media all and (min-width: 768px) {
	.axs-button-combined__link--button {
		width: auto;
	}

	.axs-button-combined[data-display-type="Primary-Secondary"] .axs-button-combined__link-wrapper {
		margin-right: var(--space-xxl);
	}

	.axs-button-combined[data-display-type="Primary-Secondary"] .axs-button-combined__link,
	.axs-button-combined[data-display-type="Text-Text"] .axs-button-combined__link {
		margin-right: var(--space-xxl);
	}
}
