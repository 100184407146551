.axs-keepmeinformed {
	background-color: #e7e9ea;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 60px;
	padding-top: 60px;
}

.axs-keepmeinformed__headline {
	margin-bottom: 30px;
}

[data-theme="dark"] .axs-keepmeinformed__headline {
	color: var(--color-white);
}

.axs-keepmeinformed__headline--deprecated ul,
.axs-keepmeinformed__headline--deprecated ol {
	display: block;
}

.axs-keepmeinformed__headline--deprecated ul {
	list-style-position: outside;
	list-style-type: disc;
}

.axs-keepmeinformed__headline--deprecated ol {
	list-style-position: outside;
	list-style-type: decimal;
}

.axs-keepmeinformed__headline--deprecated li {
	display: list-item;
	margin-left: 18px;
	padding-left: 10px;
}

.axs-keepmeinformed__headline--deprecated b,
.axs-keepmeinformed__headline--deprecated strong {
	font-family: AudiTypeExtendedBold, sans-serif !important;
	font-weight: var(--font-weight-normal);
}

.axs-keepmeinformed__headline--deprecated a {
	color: var(--color-grey-70);
	text-decoration: underline !important;
}

.axs-keepmeinformed__headline--deprecated a:hover,
.axs-keepmeinformed__headline--deprecated a:active {
	color: var(--color-black);
}
