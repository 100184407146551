.axs-md-quotes.axs-module-background {
	padding-bottom: 0;
	padding-top: 0;
}

.axs-md-quotes-align-left .single-banner-fullwidth-wrapper {
	text-align: left;
}

.axs-md-quotes-single.axs-md-quotes-align-right .single-banner-fullwidth-wrapper {
	text-align: right;
}

.axs-md-quotes-single.axs-md-quotes-align-center .single-banner-fullwidth-wrapper {
	text-align: center;
}

.axs-md-quotes-item {
	color: rgba(0, 0, 0, 1);
	position: relative;
}

.axs-md-quotes-multiple .axs-md-quotes-item .quotes-item-background,
.axs-md-quotes-single .axs-md-quotes-item .quotes-item-background {
	background-color: var(--color-white);
	background-size: cover;
	height: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.axs-md-quotes-single .axs-md-quotes-item .quotes-item-background {
	height: 0;
	justify-content: center;
	padding-bottom: 31.25%;
}

.axs-md-quotes-multiple .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-background {
	height: 0;
	padding-bottom: 56.25%;
}

.axs-md-quotes-single .single-banner-fullwidth-wrapper {
	display: flex;
	height: 100%;
	margin: 0 auto;
	max-width: 1280px;
	position: absolute;
	top: 0;
	width: 100%;
}

.axs-md-quotes-single.axs-md-quotes-align-right .axs-md-quotes-item .quotes-item-background .single-banner-fullwidth-wrapper {
	justify-content: flex-end;
}

.axs-md-quotes-single.axs-md-quotes-align-center .axs-md-quotes-item .quotes-item-background .single-banner-fullwidth-wrapper {
	justify-content: center;
}

.axs-md-quotes-item .quotes-item-background .quotes-bg-overlay {
	background-color: var(--color-black);
	height: 0;
	opacity: 0;
	padding-bottom: 56.25%;
	width: 100%;
}

.axs-md-quotes-single .axs-md-quotes-item .quotes-item-background .quotes-bg-overlay {
	height: 0;
	padding-bottom: 31.25%;
}

.quotes-item-content-inside-wrapper div:not(.quotes-item-headline),
.axs-md-quotes-headline-below.axs-md-quotes-multiple .quotes-item-content-inside-wrapper .quotes-item-headline,
.axs-md-quotes-single .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-below-wrapper .quotes-item-headline,
.axs-md-quotes-multiple:not(.axs-md-quotes-headline-inside) .quotes-item-content-inside-wrapper .quotes-item-headline,
.axs-md-quotes-multiple.axs-md-quotes-headline-inside .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-below-wrapper .quotes-item-headline,
.axs-md-quotes-single .quotes-no-background .quotes-item-background {
	display: none;
}

.axs-md-quotes-single .quotes-no-background.quotes-grey-background .quotes-item-background {
	background-color: var(--color-grey-05);
	display: block;
}

.quotes-item-content-below-wrapper {
	padding: 20px 0;
}

.axs-md-quotes-item .quotes-item-text,
.axs-md-quotes-item .quotes-no-background {
	color: rgba(0, 0, 0, 1);
}

.quotes-item-text,
.quotes-item-testimonial {
	margin: 20px 4% 0;
	width: 92%;
}

.quotes-no-background .quotes-item-content-below-wrapper {
	padding-top: 20px;
}

.axs-md-quotes-single .quotes-item-headline,
.quotes-item-content-below-wrapper .quotes-item-headline {
	margin: 0 4%;
}

.axs-md-quotes .quotes-headline::before {
	content: open-quote;
}

.axs-md-quotes .quotes-headline::after {
	content: close-quote;
}

.axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-inside-wrapper,
.axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-inside-wrapper sup[data-footnote]:hover {
	color: rgba(255, 255, 255, 1) !important;
}

.axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-inside-wrapper sup[data-footnote] {
	color: rgba(240, 240, 240, 1) !important;
}

.axs-md-quotes-item .quotes-item-content-inside-wrapper .quotes-item-headline sup[data-footnote],
.axs-md-quotes-item .quotes-item-content-below-wrapper .quotes-item-headline sup[data-footnote] {
	font-family: AudiTypeBold, Verdana, sans-serif;
}

.axs-md-quotes-single .axs-md-quotes-item .quotes-item-content-inside-wrapper {
	align-items: center;
	display: flex;
	height: 100%;
	margin: 0;
	padding: 0 4%;
	width: 48%;
}

.axs-md-quotes-single .axs-md-quotes-item.quotes-no-background .quotes-item-content-inside-wrapper {
	width: 60%;
}

.axs-md-quotes-multiple .axs-md-quotes-item .quotes-item-content-inside-wrapper {
	align-items: flex-start;
	display: flex;
	height: 100%;
	margin: 20px 0 0;
	padding: 0 4%;
	position: absolute;
	top: 0;
	width: 100%;
}

.axs-md-quotes-single .quotes-item-headline .quotes-headline {
	display: block;
}

.axs-md-quotes-mutiple .quotes-item-content-inside-wrapper .quotes-item-headline {
	margin: 0;
}

.axs-md-quotes-multiple {
	margin: 0 auto;
	max-width: 1280px;
	padding: 0 4%;
}

.axs-md-quotes-multiple .axs-md-quotes-item {
	margin-bottom: 4px;
}

.axs-md-quotes-multiple .axs-md-quotes-item:not(.quotes-no-background) {
	background-color: var(--color-grey-05);
	padding-bottom: 10px;
}

.axs-md-quotes-multiple .quotes-no-background .quotes-item-background {
	display: none;
}

.axs-md-quotes-multiple .quotes-no-background.quotes-grey-background {
	background-color: var(--color-grey-05);
}

.axs-md-quotes-multiple .quotes-no-background .quotes-item-headline .quotes-item-content-below-wrapper .quotes-headline {
	display: block;
}

@media all and (min-width: 768px) {
	.axs-md-quotes-multiple .axs-md-quotes-item .quotes-item-background,
	.axs-md-quotes-single .axs-md-quotes-item .quotes-item-background {
		position: static;
	}

	.axs-md-quotes-single .quotes-no-background .quotes-item-background {
		display: block;
	}

	.axs-md-quotes-single .axs-md-quotes-item .quotes-item-content-inside-wrapper {
		width: 60%;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper div {
		display: block;
	}

	.axs-md-quotes-single .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-inside-wrapper div,
	.axs-md-quotes-single .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-inside-wrapper .axs-el-lbl,
	.axs-md-quotes-single .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-inside-wrapper sup[data-footnote]:hover {
		color: rgba(255, 255, 255, 1) !important;
	}

	.axs-md-quotes-single .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-content-inside-wrapper sup[data-footnote] {
		color: rgba(240, 240, 240, 1) !important;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper {
		flex-direction: column;
		justify-content: center;
	}

	.axs-md-quotes-single.axs-md-quotes-align-left .quotes-item-content-inside-wrapper {
		-webkit-align-item: flex-start;
		-ms-align-item: flex-start;
		align-items: flex-start;
	}

	.axs-md-quotes-single.axs-md-quotes-align-right .quotes-item-content-inside-wrapper {
		-webkit-align-item: flex-end;
		-ms-align-item: flex-end;
		align-items: flex-end;
	}

	.axs-md-quotes-single .quotes-item-content-below-wrapper {
		display: none;
	}

	.axs-md-quotes-multiple {
		display: flex;
		flex-wrap: wrap;
	}

	.axs-md-quotes-multiple .axs-md-quotes-item:first-of-type {
		margin-right: 4px;
	}

	.axs-md-quotes-double .axs-md-quotes-item,
	.axs-md-quotes-triple .axs-md-quotes-item {
		width: calc(50% - 2px);
	}

	.quotes-no-background .quotes-item-content-below-wrapper {
		margin-bottom: 48px;
	}

	.quotes-no-background .quotes-item-content-below-wrapper .quotes-item-headline {
		margin-top: 48px;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper .quotes-item-text {
		margin-top: 6px;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper .quotes-item-testimonial {
		margin-top: 20px;
	}
}

@media all and (min-width: 1025px) {
	.axs-md-quotes-triple .axs-md-quotes-item {
		width: calc(33.33% - 2.7px);
	}

	.axs-md-quotes-multiple .axs-md-quotes-item:nth-of-type(2) {
		margin-right: 4px;
	}

	.axs-md-quotes-multiple .axs-md-quotes-item:last-of-type {
		margin-right: 0;
	}

	.axs-md-quotes-single.axs-md-quotes-align-left .axs-md-quotes-item .quotes-item-content-inside-wrapper {
		align-items: flex-start;
	}

	.axs-md-quotes-single.axs-md-quotes-align-right .axs-md-quotes-item .quotes-item-content-inside-wrapper {
		align-items: flex-end;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper .quotes-item-text {
		margin-top: 16px;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper .quotes-item-testimonial {
		margin-top: 30px;
	}
}

@media all and (min-width: 1280px) {
	.axs-md-quotes-single .quotes-item-content-inside-wrapper .axs-el-hdl-02.quotes-headline {
		font-size: 3.6rem;
		line-height: 4rem;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper .quotes-item-text {
		margin-top: 36px;
	}

	.axs-md-quotes-single .quotes-item-content-inside-wrapper .quotes-item-testimonial {
		margin-top: 50px;
	}

	.axs-md-quotes-single .single-banner-fullwidth-wrapper {
		left: 50%;
		margin-left: -640px;
		position: absolute;
		top: 0;
	}

	.axs-md-quotes-multiple {
		max-width: 1280px;
		padding: 0 2.5%;
	}
}

@media all and (max-aspect-ratio: 1/1) {
	.axs-md-quotes-single .axs-md-quotes-item:not(.quotes-no-background) .quotes-item-background,
	.axs-md-quotes-single .axs-md-quotes-item .quotes-item-background .quotes-bg-overlay {
		padding-bottom: 56.25%;
	}
}
